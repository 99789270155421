import React, { useMemo } from 'react'
import { range } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Constants, Enum } from '../../../types'
import { helpTip, setYearMonthValueFromMonths, validateObjectValue } from '../../../services/utils'
import { Alert } from '../../../components'
import { HomeInformationInput, HomeInformationInputSchema } from './PrequalificationSchema'
import { FORM_ID } from './StepperFormFooter'

declare global {
  interface Window {
    initAutocomplete: () => void
  }
}

type Props = {
  applicantName: string
  applicantData: HomeInformationInput
  forceProvince?: string
  onApplicantUpdated: (data: HomeInformationInput) => void
  setDisableNext: (disable: boolean) => void
}

function AddressForm({
  forceProvince,
  applicantData,
  onApplicantUpdated: onApplicantUpdated,
  applicantName,
  setDisableNext,
}: Props) {
  const { t } = useTranslation()
  const yearSelectRef = React.useRef<HTMLSelectElement>(null)
  const monthSelectRef = React.useRef<HTMLSelectElement>(null)

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    getValues,
    trigger,
  } = useForm<HomeInformationInput>({
    mode: 'onBlur',
    resolver: yupResolver(HomeInformationInputSchema),
    defaultValues: applicantData,
  })

  React.useEffect(() => {
    const months = applicantData.currentAddress.months

    if (months > 0)
      setYearMonthValueFromMonths(
        months,
        yearSelectRef.current as HTMLSelectElement,
        monthSelectRef.current as HTMLSelectElement,
      )

    validateObjectValue(getValues(), trigger)
    helpTip()
  }, [applicantData])

  const homeFeeTypeId = watch('expenses.homeFeeTypeId')

  const totalMonthlyHomePayment = watch('expenses.totalMonthlyHomePayment')

  const province = watch('currentAddress.stateIso')

  React.useEffect(() => {
    if (homeFeeTypeId === Enum.EHomeFeeType.Own) setValue('expenses.totalMonthlyHomePayment', 0)
  }, [homeFeeTypeId])

  React.useEffect(() => {
    if (totalMonthlyHomePayment > 0 && homeFeeTypeId === Enum.EHomeFeeType.Own)
      setValue('expenses.homeFeeTypeId', Enum.EHomeFeeType.OwnWithMortgage)
  }, [totalMonthlyHomePayment])

  const onChangeMonth = () => {
    const year = +(yearSelectRef.current?.value ?? 0)
    const month = +(monthSelectRef.current?.value ?? 0)

    const months = year * 12 + month

    setValue('currentAddress.months', months)

    if (months > 0) clearErrors('currentAddress.months')
  }

  const uncoveredProvince = useMemo(() => {
    const uncovered: boolean = !!province && Constants.UncoveredProvinces.some((x) => x.includes(province))
    setDisableNext(uncovered)
    return uncovered
  }, [province])

  return (
    <section className="step-content">
      <h3 className="form-question">{t('address.provideAddress', { firstName: applicantName })}</h3>

      <form onSubmit={handleSubmit(onApplicantUpdated)} id={FORM_ID}>
        <div className={`control-group mid  ${errors.currentAddress?.civicNumber && 'error'}`}>
          <label htmlFor="civic_number">{t('address.addressNumber')}</label>
          <input type="text" id="civicNumber" {...register('currentAddress.civicNumber')} maxLength={10} />
        </div>

        <div className={`control-group mid  ${errors.currentAddress?.street && 'error'}`}>
          <label htmlFor="street_name">{t('address.streetName')}</label>
          <input type="text" id="street" {...register('currentAddress.street')} maxLength={100} />
        </div>

        <div className={`control-group mid  ${errors.currentAddress?.apartment && 'error'}`}>
          <label htmlFor="apartment_number">{t('address.apartmentNumber')}</label>
          <input type="text" id="apartment" {...register('currentAddress.apartment')} maxLength={50} />
        </div>

        <div className={`control-group mid  ${errors.currentAddress?.city && 'error'}`}>
          <label htmlFor="city">{t('address.city')}</label>
          <input type="text" id="city" {...register('currentAddress.city')} maxLength={50} />
        </div>
        {uncoveredProvince && (
          <Alert
            type="warn"
            message={t('common.uncoveredProvinces', {
              province: province === 'PE' ? t('address.provincelowercase') : t('address.territory'),
            })}
          />
        )}
        <div className="control-group mid">
          <label htmlFor="select">{t('address.province')}</label>
          <div className={`dropdown-wrap ${errors.currentAddress?.stateIso && 'error'}`}>
            <select id="stateIso" {...register('currentAddress.stateIso')} defaultValue="">
              {!forceProvince && (
                <>
                  <option value="" disabled>
                    {' '}
                  </option>
                  {Object.values(Enum.EProvince).map((value) => (
                    <option value={value} key={value}>
                      {t(`enum.eProvince.${value}`)}
                    </option>
                  ))}
                </>
              )}
              {forceProvince && <option value={forceProvince}>{t(`enum.eProvince.${forceProvince}`)}</option>}
            </select>
          </div>
        </div>

        <div className={`control-group mid  ${errors.currentAddress?.postalCode && 'error'}`}>
          <label htmlFor="postal_code">{t('address.postalCode')}</label>
          <input type="text" id="postalCode" {...register('currentAddress.postalCode')} maxLength={7} />
        </div>

        <div className="control-group" style={{ paddingBottom: 0 }}>
          <label style={{ marginBottom: 0 }}>
            {t('livingAtAddressSince.howLong')}
            <span className="help-tip">
              <p>{t('livingAtAddressSince.details')}</p>
            </span>
          </label>
        </div>

        <div className="control-group mid">
          <div className="dropdown-wrap">
            <select id="year" name="year" onChange={onChangeMonth} ref={yearSelectRef} defaultValue={0}>
              <option value={0}>{t('common.years')}</option>
              {range(1, 12).map((item) => (
                <option value={item} key={item}>
                  {t('common.yearsLabel', { count: item })}
                </option>
              ))}
              <option value={12}>
                {t('common.moreThan')} {t('common.yearsLabel', { count: 12 })}
              </option>
            </select>
          </div>
        </div>

        <div className="control-group mid">
          <div className={`dropdown-wrap ${errors.currentAddress?.months && 'error'}`}>
            <select id="month" name="month" onChange={onChangeMonth} ref={monthSelectRef} defaultValue={0}>
              <option value={0}>{t('common.months')}</option>
              {range(1, 12).map((item) => (
                <option value={item} key={item}>
                  {t('common.monthsLabel', { count: item })}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="control-group" style={{ paddingBottom: 0 }}>
          <label style={{ marginBottom: 0 }}>
            {t('housing.currentStatus')}
            <span className="help-tip">
              <p>{t('livingAtAddressSince.details')}</p>
            </span>
          </label>
        </div>

        <div className="control-group mid">
          <label htmlFor="housing_status">{t('housing.homeFeeType')}</label>
          <div className={`dropdown-wrap ${errors.expenses?.homeFeeTypeId && 'error'}`}>
            <select {...register('expenses.homeFeeTypeId')} id="homeFeeTypeId">
              {Object.values(Enum.EHomeFeeType).map((item) => (
                <option key={item} value={item}>
                  {t(`enum.eHomeFeeType.${item}`)}
                </option>
              ))}
            </select>
          </div>
        </div>

        {homeFeeTypeId !== Enum.EHomeFeeType.Own && homeFeeTypeId !== Enum.EHomeFeeType.WithParents && (
          <div className={`control-group mid  ${errors.expenses?.totalMonthlyHomePayment && 'error'}`}>
            <label htmlFor="mortgage_amount">
              {t('housing.totalMonthlyPayment', { homeFeeType: t(`housing.${homeFeeTypeId}`) })}
            </label>
            <div className="number-wrap">
              <input type="number" id="totalMonthlyHomePayment" {...register('expenses.totalMonthlyHomePayment')} />
            </div>
          </div>
        )}
        {(totalMonthlyHomePayment > 0 || homeFeeTypeId === Enum.EHomeFeeType.WithParents) && (
          <div className={`control-group  ${errors.expenses?.housingMonthly && 'error'}`}>
            <label htmlFor="mortgage_payment">
              {t('housing.contributedMonthlyPayment', {
                homeFeeType: t(`housing.${homeFeeTypeId}`),
              })}
            </label>
            <div className="number-wrap">
              <input type="number" id="monthlyHomePayment" {...register('expenses.housingMonthly')} />
            </div>
          </div>
        )}
      </form>
    </section>
  )
}

export default AddressForm
