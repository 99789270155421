import React from 'react'
import { Enum } from '@src/types/constants'
import ApprovedPrequalForm from './components/ApprovedPrequalForm'
import DeclinedPrequalForm from './components/DeclinedPrequalForm'
import FailedToObtainCreditReportForm from './components/FailedToObtainCreditReportForm'
import { FilteredCreditApplication } from '@src/api/credit-api'

type Props = {
  creditApp: FilteredCreditApplication
}
function PrequalResultPage({ creditApp }: Props) {
  return (
    <div>
      {creditApp.prequalificationDecision !== null && (
        <>
          {creditApp.prequalificationDecision.decision === Enum.EPrequalificationDecision.Approved && (
            <ApprovedPrequalForm creditApp={creditApp} />
          )}

          {creditApp.prequalificationDecision.decision === Enum.EPrequalificationDecision.Refused && (
            <DeclinedPrequalForm creditApp={creditApp} />
          )}

          {creditApp.prequalificationDecision.decision === Enum.EPrequalificationDecision.Pending && (
            <FailedToObtainCreditReportForm creditApp={creditApp} />
          )}
        </>
      )}
    </div>
  )
}

export default PrequalResultPage
