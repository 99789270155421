export const calculateMonthlyAmount = (
  loanTerm: number,
  interestRate: number,
  loanAmount: number,
  financeFee: number,
) => {
  const monthlyInterestRate = interestRate / 100 / 12
  const numerator = (loanAmount + loanAmount * financeFee) * monthlyInterestRate
  const denominator = 1 - (1 + monthlyInterestRate) ** -loanTerm
  const monthlyPayment = numerator / denominator

  return monthlyPayment
}
