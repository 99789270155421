import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate, FormatCurrencyRoundedUpToNoDecimals } from '@src/services/Formatter'
import {
  useSubmitRefinancing,
  SubmitRefinancingDto,
  SubmitRefinancingResultDto,
  ApplicationRefinancingParams,
} from '@src/api/refinance-api'
import { AsyncActionButton } from '@src/components'
import { Constants } from '@src/types'

type Props = {
  onSubmitted: (data: SubmitRefinancingResultDto) => void
  refinancingParams: ApplicationRefinancingParams
  creditApplicationId: string
}

function RefinancingCreditApplicationForm({ onSubmitted, refinancingParams, creditApplicationId }: Props) {
  const { t } = useTranslation()

  const [canSubmitRequestedAmount, setCanSubmitRequestedAmount] = React.useState<boolean>(true)
  const [canSubmitRequestedPaymentOn, setCanSubmitRequestedPaymentOn] = React.useState<boolean>(true)
  const [requestedLoanAmount, setRequestedAmount] = React.useState<number>(Constants.MinLoanAmount)
  const [requestedPaymentOn, setRequestedPaymentOn] = React.useState<Date>(new Date())
  const [includeInsurance, setIncludeInsurance] = React.useState<boolean>(false)
  const [submitRefinance, isSubmitting] = useSubmitRefinancing()

  const handleSubmit = () => {
    const data: SubmitRefinancingDto = {
      id: creditApplicationId,
      includeInsurance,
      requestedLoanAmount,
      requestedPaymentOn,
    }
    submitRefinance(data).then(onSubmitted)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === 'requestedAmount') {
      setRequestedAmount(Number(e.target.value))
      if (
        Number(e.target.value) >= Constants.MinLoanAmount &&
        Number(e.target.value) <= Number(refinancingParams?.availableAmount)
      )
        setCanSubmitRequestedAmount(true)
      else setCanSubmitRequestedAmount(false)
    }

    if (e.target.id === 'requestedPaymentOn') {
      const newDate = new Date(`${e.target.value} 00:00:00`)
      const now = new Date()
      const expiresOn = new Date(refinancingParams?.expiresOn ?? '')

      setRequestedPaymentOn(expiresOn)

      if (newDate <= expiresOn && newDate >= now) setCanSubmitRequestedPaymentOn(true)
      else setCanSubmitRequestedPaymentOn(false)
    }
    if (e.target.id === 'includeInsurance') setIncludeInsurance(e.target.checked)
  }

  return (
    <main className="general-message" style={{ display: 'flex' }}>
      <form>
        <h4 className="subtitle">{t('common.financeApplication')}</h4>
        <h3>
          {refinancingParams?.applicantFirstName} {refinancingParams?.aplicantMiddleName}{' '}
          {refinancingParams?.applicantLastName}
          <br />
          {t('refinancing.referenceNumber')}: {refinancingParams.referenceNumber}
          <br />
          {t('refinancing.submittedOn')}: {formatDate(refinancingParams.createOn)}
        </h3>
        <h1>
          {t('refinancing.availableAmount', {
            amount: FormatCurrencyRoundedUpToNoDecimals(refinancingParams.availableAmount),
          })}
        </h1>
        <div className="paragraph">
          <p>{t('refinancing.messageInfo')}</p>
          {refinancingParams?.canAddInsurance && <p>{t('refinancing.messageInsurance')}</p>}
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <div className="control-group mid">
              <label htmlFor="name">{t('refinancing.requestedAmount')}</label>
              <input type="number" id="requestedAmount" defaultValue={requestedLoanAmount} onChange={handleChange} />
            </div>

            <div className="control-group mid">
              <label htmlFor="last_name">{t('refinancing.requestedPaymentOn')}</label>
              <input
                type="date"
                id="requestedPaymentOn"
                defaultValue={formatDate(requestedPaymentOn)}
                onChange={handleChange}
                min={new Date().toISOString().split('T')[0]}
              />
            </div>
          </div>
          {refinancingParams.canAddInsurance && (
            <div className="paragraph">
              <p>{t('worksheet.includeInsurance')}</p>
              <div className="control-group">
                <input type="checkbox" id="includeInsurance" />
                <label htmlFor="includeInsurance">{t('common.yes')}</label>
              </div>
            </div>
          )}
        </div>

        <AsyncActionButton
          type="button"
          className="btn btn-blue"
          onClick={handleSubmit}
          disabled={!(canSubmitRequestedAmount && canSubmitRequestedPaymentOn)}
          isPending={isSubmitting}
        >
          {t('common.submit')}
          <i className="fa-regular fa-arrow-right" />
        </AsyncActionButton>
      </form>
    </main>
  )
}

export default RefinancingCreditApplicationForm
