import React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ContactInformationInput, ContactInformationInputSchema } from './PrequalificationSchema'
import {
  formatPhonesNumber,
  helpTip,
  phoneFormat,
  removePhoneFormat,
  validateObjectValue,
} from '../../../services/utils'
import { FORM_ID } from './StepperFormFooter'

type Props = {
  applicantData: ContactInformationInput
  onApplicantUpdated: (data: ContactInformationInput) => void
  prohibedPhone?: string
}

function ContactInformationForm({ applicantData, onApplicantUpdated, prohibedPhone }: Props) {
  const { t } = useTranslation()

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useForm<ContactInformationInput>({
    mode: 'onBlur',
    resolver: yupResolver(ContactInformationInputSchema),
    defaultValues: applicantData,
  })

  React.useEffect(() => {
    formatPhonesNumber()
    validateObjectValue(getValues(), trigger)
    helpTip()
  }, [])

  const handlePhoneOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target
    const phone = removePhoneFormat(value)
    if (prohibedPhone && prohibedPhone === phone) {
      event.target.setCustomValidity(t('contact.coappSameMobileError'))
    } else {
      event.target.setCustomValidity('')
      setValue(id as 'cellPhone' | 'homePhone', phone)
      trigger(id as 'cellPhone' | 'homePhone')
    }
  }

  return (
    <section className="step-content">
      <h3 className="form-question">{t('contact.provideInformation')}</h3>
      <form onSubmit={handleSubmit(onApplicantUpdated)} id={FORM_ID}>
        <div className={`control-group mid  ${errors.cellPhone && 'error'}`}>
          <label htmlFor="mobile_phone"> {t('contact.mobilePhone')}</label>
          <input
            id="cellPhone"
            type="tel"
            placeholder="( ___ ) ___-____"
            className="phone-input"
            defaultValue={phoneFormat(getValues('cellPhone'))}
            onInput={handlePhoneOnchange}
          />
        </div>
        <div className={`control-group mid  ${errors.homePhone && 'error'}`}>
          <label htmlFor="otherphone">{t('contact.otherPhone')}</label>
          <input
            id="homePhone"
            type="tel"
            placeholder="( ___ ) ___-____"
            className="phone-input"
            defaultValue={phoneFormat(getValues('homePhone') as string)}
            onInput={handlePhoneOnchange}
          />
        </div>
        <div className={`control-group  ${errors.email && 'error'}`}>
          <label htmlFor="email">
            {t('contact.emailAddress')}
            <span className="help-tip">
              <p>{t('contact.emailAddressInfo')}</p>
            </span>
          </label>
          <input id="email" type="email" {...register('email')} />
        </div>

        {/*  SUBSCRIBE NEWSLATTER AFTER MVP !IMPORTANT */}
        <div className="empty-space" />
        <div className="clarification">
          <p>
            {t('contact.newsletter')}
            <u>
              <a href={t('common.policyLink')} target="_blank" rel="noreferrer">
                {t('contact.linkText')}
              </a>
            </u>
            {t('contact.newsletterEnd')}
          </p>
        </div>
        <div className="control-group">
          <input type="checkbox" id="newsletter" {...register('subscribeNewsletter')} />
          <label htmlFor="newsletter">{t('contact.subscribe')}</label>
        </div>
      </form>
    </section>
  )
}

export default ContactInformationForm
