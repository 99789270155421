import { IFINANCE_ID, MEDICARD_ID } from '@src/data/merchant-selectors'
import i18n from '@src/main'
import { isMedicardWebSite } from '@src/services/query-string'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

type props = {
  reason: string
}

type information = {
  title: string
  message: string
}

export default function MerchantMissingPage({ reason }: props) {
  const { t } = useTranslation()
  const [information, setInformation] = React.useState<information | null>(null)
  const [baseUrl, setBaseUrl] = React.useState<string>('')

  const buildBaseUrl = () => {
    let url = window.location.href
    let baseUrl = url.split('/get-merchant-error')[0]
    if (isMedicardWebSite()) baseUrl = baseUrl + '/' + MEDICARD_ID
    else baseUrl = baseUrl + '/' + IFINANCE_ID
    setBaseUrl(baseUrl)
  }

  useEffect(() => {
    let errorPageInformation: information | null = null
    switch (reason) {
      case 'merchantNotFound':
        errorPageInformation = {
          title: t('merchant.merchantNotFound'),
          message: t('merchant.merchantNotFoundMessage'),
        }
        break
      case 'merchantInactive':
        errorPageInformation = {
          title: t('merchant.merchantInactive'),
          message: t('merchant.merchantInactiveMessage'),
        }
        break
      default:
        errorPageInformation = {
          title: t('common.notFound'),
          message: t('common.unexpectedError'),
        }
        break
    }
    buildBaseUrl()
    setInformation(errorPageInformation)
  }, [reason, i18n.language])

  return (
    <main className="general-message">
      <h4 className="subtitle">{information?.title}</h4>
      <h1>{t('common.oopsTitle')}</h1>
      <div className="paragraph">
        <p style={{ fontWeight: 'bold' }}>{information?.message}</p>
        <p style={{ fontWeight: 'normal' }}>{t('merchant.contactUs.title')}</p>
        <ul style={{ listStyle: 'disc', margin: '0 1.5em' }}>
          <Trans>
            <li>
              {t('merchant.contactUs.line_1')}
              <a href="tel:1-888-689-9876" style={{ textDecoration: 'none', color: '#08abf0' }}>
                1&#x2011;888&#x2011;689&#x2011;9876
              </a>{' '}
            </li>
            <li>
              <Trans
                i18nKey="merchant.contactUs.line_2"
                components={{ 1: <a style={{ color: '#08abf0' }} href={baseUrl} /> }}
              />
            </li>
          </Trans>
        </ul>
      </div>
    </main>
  )
}
