import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

type Props = {
  creditAppId: string
  useChangeLabel?: boolean
}

function ManageCoappButton({ creditAppId, useChangeLabel }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <button
      type="button"
      className="btn secondary-action-btn btn-simple"
      onClick={() => navigate(`/creditapplication/${creditAppId}/addcoapplicant/personal-information`)}
    >
      <i className="fa-regular fa-user-plus" />
      {useChangeLabel ? t('common.changeCoapplicant') : t('common.addCoapplicant')}
    </button>
  )
}

export default ManageCoappButton
