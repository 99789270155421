import { RequiredDocument } from '@src/api/credit-api'
import { FieldError } from 'react-hook-form'

export * from './constants'

export function translateErrorCode(err: FieldError): string {
  if (err.type === 'required') return 'common.errors.required'
  if (err.type === 'test-Sin-type') return 'common.errors.number'
  return err.message ?? ''
}

export const EMPTY_ARRAY = []

export enum EApplicationStep {
  Credit = 'credit',
  Finaning = 'financing',
  CVT = 'CVT',
  Payout = 'payout',
}

export type ApplicantType = 'applicant' | 'coApplicant'

export type RequiredDocumentWithStatus = RequiredDocument & {
  status: string
  refusalReason: string | null
}
