import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import RefinancingCreditApplicationForm from './RefinanceForm'
import Loader from '@src/components/Loader'
import { SubmitRefinancingResultDto, useGetRefinancingParams } from '@src/api/refinance-api'
import { isRefinancingPossible } from './hooks'

function RefinancingCreditApplicationContainer() {
  const params = useParams()
  const id = params.id as string
  const { t } = useTranslation()

  const [refinanceParams, isLoadingRefinance] = useGetRefinancingParams(id)

  const [newAppInfo, setNewAppInfo] = useState<SubmitRefinancingResultDto | null>(null)

  const canRefinance = isRefinancingPossible(refinanceParams)

  if (isLoadingRefinance) return <Loader title={t('common.withYouShortly')} description="" />

  return (
    <div>
      {!canRefinance && (
        <main className="general-message">
          <h4 className="subtitle">{t('common.financeApplication')}</h4>
          <h3>{t('refinancing.noRefinancing')}</h3>
        </main>
      )}

      {canRefinance && !newAppInfo && refinanceParams && (
        <RefinancingCreditApplicationForm
          creditApplicationId={id}
          refinancingParams={refinanceParams}
          onSubmitted={setNewAppInfo}
        />
      )}

      {newAppInfo && (
        <main className="general-message">
          <h4 className="subtitle">{t('common.financeApplication')}</h4>
          <h1>
            #{newAppInfo.referenceNumber} {t('refinancing.confirmation.inReview')}
          </h1>
          <div className="paragraph">
            <p>{t('refinancing.confirmation.messageLine1')}.</p>
            <p>{t('refinancing.confirmation.messageLine2')}</p>
          </div>
        </main>
      )}
    </div>
  )
}

export default RefinancingCreditApplicationContainer
