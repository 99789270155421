export class Enum {
  static readonly EOtherIncomeTypes = {
    familyAllocations: 1,
    workIncidentIndemnisation: 3,
    maternityPaternityIncome: 4,
    retirementIncome: 8,
    disabilityIncome: 11,
    other: 6,
  }

  static readonly EDefaultPaymentPlanIdEnum = {
    RegularDailyInterests: 'regularInterest',
    RegularPeriodInterests: 'regularPeriod',
  }

  static readonly EPaymentFrequencyEnum = {
    BiWeekly: 'biWeekly',
    Monthly: 'monthly',
  }

  static readonly EReviewLink = {
    Medicard: 'https://g.page/r/CYpoFarvZlYMEBI/review',
    IFinance: 'https://g.page/r/CbeNfU0EirsoEBE/review',
  }

  static readonly EJobType = {
    fullTime: 0,
    partTime: 1,
    Seasonal: 3,
    SelfEmployed: 5,
    Unemployed: 6,
  }

  static readonly ETermPersonalLoan = {
    twelve: 12,
    twentyFour: 24,
    thirtySix: 36,
    fortyEight: 48,
    sixty: 60,
    seventyTwo: 72,
  }

  static readonly EBeneficiaryType = {
    Applicant: 1,
    Coapplicant: 2,
    Other: 3,
  }

  static readonly EPaymentMethods = {
    check: 'check',
    bankTransfer: 'bankTransfer',
  }

  static readonly ERequiredExternalStepStatus = {
    WaitingForClient: 'notStarted',
    WaitingForBank: 'inProgress',
    Completed: 'completed',
  }

  static readonly ELoanPurpose = {
    Veterinary: 'veterinary',
    Medical: 'medical',
    Aesthetics: 'aesthetics',
    HomeImprovement: 'homeImprovement',
    Dentistry: 'dentistry',
    GoodsAndServices: 'goodsAndServices',
    Other: 'other',
  }

  static readonly EDecision = {
    Approved: 'approved',
    Partial: 'partial',
    Refused: 'refused',
    Error: 'error',
    Pending: 'pending',
    ManualRevision: 'manual-revision',
  }

  static readonly ECreditApplicationStatus = {
    Cancelled: 'cancelled',
    Active: 'active',
    Draft: 'draft',
    Completed: 'completed',
  }

  static readonly EPrequalificationDecision = {
    Approved: 'approved',
    Pending: 'pending',
    Refused: 'refused',
    Error: 'error',
  }

  static readonly EFinalDecision = {
    Approved: 'approved',
    Pending: 'pending',
    Refused: 'refused',
    ManualRevision: 'manual-revision',
    Error: 'error',
  }

  static readonly EQuestionType = {
    SingleChoice: 'singleChoice',
  }

  static readonly ELanguage = {
    French: 0,
    English: 1,
  }

  static readonly EApplicantType = {
    Applicant: 'applicant',
    Coapplicant: 'coApplicant',
  }

  static readonly ERelationToApplicant = {
    Spouse: 'SP',
    CommonLaw: 'CL',
    Employer: 'Employer',
    InLaw: 'IL',
    Parental: 'PR',
    GrandMother: 'GM',
    GrandFather: 'GF',
    Brother: 'B',
    Sister: 'S',
    Other: 'Other',
  }

  static readonly EHomeFeeType = {
    Rent: 'rent',
    Own: 'own',
    OwnWithMortgage: 'ownWithMortgage',
    WithParents: 'withParents',
  }

  static readonly EProvince = {
    quebec: 'QC',
    ontario: 'ON',
    novaScotia: 'NS',
    newBrunswick: 'NB',
    princeEdwardIsland: 'PE',
    newfoundland: 'NL',
    britishColumbia: 'BC',
    saskatchewan: 'SK',
    alberta: 'AB',
    manitoba: 'MB',
    yukon: 'YT',
    nunavut: 'NU',
    northwestTerritories: 'NT',
  }

  static readonly EDocumentStatus = {
    AwaitingDocument: 'awaitingDocument',
    AwaitingApproval: 'awaitingApproval',
    Incomplete: 'incomplete',
    Refused: 'refused',
    Approved: 'approved',
  }

  static readonly EWorksheetStatus = {
    Draft: 'draft',
    Active: 'active',
    Completed: 'completed',
  }

  static readonly EDocumentType = {
    SecondIdWithPhoto: 33,
    ValidDriverLicense: 41,
    SignedCVT: 47,
    ValidPhotoId: 67,
  }

  static readonly EIncomeProofTypes = {
    LastPayStubJob: 17,
    FamilyAllocationsProof: 68,
    WorkIncidentIndemnisationProof: 69,
    MaternityPaternityIncomeProof: 70,
    OtherIncomeProof: 71,
    RetirementIncomeProof: 72,
    DisabilityIncomeProof: 73,
    AssistanceProof: 74,
    EmployementInsuranceProof: 75,
    T4: 37,
    T4E: 38,
    NoticeOfAssessment: 58,
    ProofOfIncome: 59,
    ProofOfBenefits: 25,
    ChildTaxBenefitStatement: 53,
    MaternityLeaveBenefit: 21,
  }

  static readonly EExecutionStatus = {
    NotStarted: 'notStarted',
    InProgress: 'inProgress',
    Completed: 'completed',
    Error: 'error',
  }

  static readonly EPromoCodeStatus = {
    Invalid: 'invalid',
    Expired: 'expired',
    Valid: 'valid',
  }

  static readonly ETrackingEvents = {
    GetStarted: 'get_started',

    PrequalSuccess: 'prequal_success',
    PrequalDeclined: 'prequal_declined',

    QualifSuccess: 'qualif_success',
    QualifDeclined: 'qualif_declined',

    WithdrewManualRevision: 'withdrew_manual_revision',
    WithdrewAfterPrequal: 'withdrew_after_prequal',
    WithdrewAfterQualif: 'withdrew_after_qualif',
  }
}

export enum EFlinksEventSteps {
  SESSION_STORAGE_BLOCKED = 'SESSION_STORAGE_BLOCKED',
  SESSION_STORAGE_BLOCKED_RETRY = 'SESSION_STORAGE_BLOCKED_RETRY',
  INSTITUTION_SELECTED = 'INSTITUTION_SELECTED',
  COMPONENT_LOAD_CREDENTIAL = 'COMPONENT_LOAD_CREDENTIAL',
  COMPONENT_ACCEPT_CONSENT = 'COMPONENT_ACCEPT_CONSENT',
  COMPONENT_DENY_CONSENT = 'COMPONENT_DENY_CONSENT',
  COMPONENT_ACCEPT_TERMS = 'COMPONENT_ACCEPT_TERMS',
  COMPONENT_DENY_TERMS = 'COMPONENT_DENY_TERMS',
  COMPONENT_CLICK_TERMS_URL = 'COMPONENT_CLICK_TERMS_URL',
  SUBMIT_CREDENTIAL = 'SUBMIT_CREDENTIAL',
  RETRY_COUNT = 'RETRY_COUNT',
  MAXIMUM_RETRY_REACHED = 'MAXIMUM_RETRY_REACHED',
  QUESTION_NOT_FOUND = 'QUESTION_NOT_FOUND',
  COMPONENT_LOAD_MFA = 'COMPONENT_LOAD_MFA',
  SUBMIT_MFA = 'SUBMIT_MFA',
  SUBMIT_GET_MFA_QUESTIONS = 'SUBMIT_GET_MFA_QUESTIONS',
  ENHANCED_MFA_HAS_QUESTIONS = 'ENHANCED_MFA_HAS_QUESTIONS',
  ENHANCED_MFA_NO_QUESTIONS = 'ENHANCED_MFA_NO_QUESTIONS',
  SUBMIT_ANSWER_MFA_QUESTIONS = 'SUBMIT_ANSWER_MFA_QUESTIONS',
  ENHANCED_MFA_SUCCESS = 'ENHANCED_MFA_SUCCESS',
  SKIP_ENHANCED_MFA = 'SKIP_ENHANCED_MFA',
  COMPONENT_LOAD_ACCOUNT_SELECTION = 'COMPONENT_LOAD_ACCOUNT_SELECTION',
  COMPONENT_LOAD_INSTITUTION_SELECTOR = 'COMPONENT_LOAD_INSTITUTION_SELECTOR',
  ACCOUNT_SELECTED = 'ACCOUNT_SELECTED',
  REDIRECT = 'REDIRECT',
}

export const Constants = {
  FinanceFeeRateForRegular: 0.06,
  UpperThresholdAgeForApplicant: 75,
  UpperThresholdAgeForCoapplicant: 75,
  BaseMinTerm: 12,
  BaseMaxTerm: 72,
  CorrectedMaxTerm: 60,
  SupportedDocumentTypes: '.jpg, .jpeg, .png, .pdf, .heic, .heif',
  UncoveredProvinces: [
    Enum.EProvince.yukon,
    Enum.EProvince.nunavut,
    Enum.EProvince.northwestTerritories,
    Enum.EProvince.princeEdwardIsland,
  ],
  monthNames: {
    1: { nameEn: 'January', nameFr: 'Janvier' },
    2: { nameEn: 'February', nameFr: 'Février' },
    3: { nameEn: 'March', nameFr: 'Mars' },
    4: { nameEn: 'April', nameFr: 'Avril' },
    5: { nameEn: 'May', nameFr: 'Mai' },
    6: { nameEn: 'June', nameFr: 'Juin' },
    7: { nameEn: 'July', nameFr: 'Juillet' },
    8: { nameEn: 'August', nameFr: 'Août' },
    9: { nameEn: 'September', nameFr: 'Septembre' },
    10: { nameEn: 'October', nameFr: 'Octobre' },
    11: { nameEn: 'November', nameFr: 'Novembre' },
    12: { nameEn: 'December', nameFr: 'Décembre' },
  } as { [month: number]: { nameEn: string; nameFr: string } },
  IncomeProofTypes: Object.values(Enum.EIncomeProofTypes) as number[],
  ExpectingDocumentStatuses: [
    Enum.EDocumentStatus.AwaitingDocument,
    Enum.EDocumentStatus.Incomplete,
    Enum.EDocumentStatus.Refused,
  ],
  MinIncomes: 21000,
  MinLoanAmount: 500,
  MaxLoanAmount: 40000,
  MaxRate: 29.99,
}
