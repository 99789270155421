import React from 'react'
import { useTranslation } from 'react-i18next'

function DecisionningErrorTile() {
  const { t } = useTranslation()

  return (
    <main className="general-message">
      <h4 className="subtitle">{t('common.AnalysisInProgress')}</h4>
      <h3>{t('common.errorObtainingCreditReport')}</h3>
    </main>
  )
}

export default DecisionningErrorTile
