import {
  FilteredApplicant,
  FilteredCreditApplication,
  RequiredDocument,
  CreditApplicationDocument,
  ContractStatusEnum,
} from '@src/api/credit-api'
import { Constants, Enum, EApplicationStep, ApplicantType, EMPTY_ARRAY, RequiredDocumentWithStatus } from '../types'
import { calculateMonthlyAmount } from './compute-selectors'

export function mustAskApplicantSIN(filteredCreditApplication: FilteredCreditApplication) {
  return !filteredCreditApplication.applicant.hasSin && filteredCreditApplication.applicant.isCreditTwoYearsOrLess
}

export function mustAskCoapplicantSIN(filteredCreditApplication: FilteredCreditApplication) {
  return (
    filteredCreditApplication.coapplicant &&
    !filteredCreditApplication.coapplicant.hasSin &&
    filteredCreditApplication.coapplicant.isCreditTwoYearsOrLess
  )
}

export function getLoanAmount(creditApp: FilteredCreditApplication) {
  if (creditApp && creditApp.finalDecision) {
    const amount = Math.min(creditApp.requestedLoanAmount, creditApp.finalDecision.maxAmountFinanced)
    return amount
  }
  return 0
}

export function getPrequalifiedAmount(creditApp: FilteredCreditApplication) {
  return Math.min(creditApp.requestedLoanAmount, creditApp.prequalificationDecision?.maxLoanAmount ?? Number.MAX_VALUE)
}

export function getMinPaymentForRequestedAmount(creditApplication: FilteredCreditApplication | null) {
  if (creditApplication && creditApplication.prequalificationDecision) {
    const amount = Math.min(
      creditApplication?.requestedLoanAmount ?? Number.MAX_VALUE,
      creditApplication?.prequalificationDecision.maxLoanAmount ?? Number.MAX_VALUE,
    )
    return calculateMonthlyAmount(
      creditApplication.applicant.age >= Constants.UpperThresholdAgeForApplicant
        ? Constants.CorrectedMaxTerm
        : Constants.BaseMaxTerm,
      creditApplication?.prequalificationDecision.minInterestRate,
      amount,
      Constants.FinanceFeeRateForRegular,
    )
  }
  return 0
}

export function getMaxPaymentForRequestedAmount(creditApplication: FilteredCreditApplication | null) {
  if (creditApplication && creditApplication.prequalificationDecision) {
    const amount = Math.min(
      creditApplication?.requestedLoanAmount ?? Number.MAX_VALUE,
      creditApplication?.prequalificationDecision.maxLoanAmount ?? Number.MAX_VALUE,
    )
    return calculateMonthlyAmount(
      creditApplication.applicant.age >= Constants.UpperThresholdAgeForApplicant
        ? Constants.CorrectedMaxTerm
        : Constants.BaseMaxTerm,
      creditApplication?.prequalificationDecision.maxInterestRate > 0
        ? creditApplication?.prequalificationDecision.maxInterestRate
        : Constants.MaxRate,
      amount,
      Constants.FinanceFeeRateForRegular,
    )
  }
  return 0
}

export function hasMissingDocuments(creditApp: FilteredCreditApplication, forStep?: EApplicationStep): boolean {
  const reqDocs = forStep
    ? creditApp.requiredDocuments.filter((x) => x.requiredBeforeStep === forStep)
    : creditApp.requiredDocuments

  return !reqDocs.every(
    (req) =>
      req.typeId === Enum.EDocumentType.SignedCVT ||
      creditApp.documents.findIndex(
        (doc) => isDocumentMatchingRequiredDoc(doc, req) && !Constants.ExpectingDocumentStatuses.includes(doc.status),
      ) > -1,
  )
}

export const hasSignedContract = (creditApp: FilteredCreditApplication | null): boolean => {
  if (!creditApp) return false

  const idx = creditApp.documents.findIndex(
    (x) =>
      x.typeId === Enum.EDocumentType.SignedCVT &&
      (x.status === Enum.EDocumentStatus.AwaitingApproval || x.status === Enum.EDocumentStatus.Approved),
  )

  return idx > -1
}

export function areAllDocumentsApproved(creditApp: FilteredCreditApplication): boolean {
  return creditApp.documents.every((d) => d.status === Enum.EDocumentStatus.Approved) && !hasMissingDocuments(creditApp)
}

export function hasApplicantSubmittedAllRequiredIncomeDocuments(
  reqDocWithStatus: RequiredDocumentWithStatus[],
  applicantType: ApplicantType,
): boolean {
  var applicantIncomeDocs = reqDocWithStatus.filter(
    (x) => x.applicantType === applicantType && Constants.IncomeProofTypes.indexOf(x.typeId) > -1,
  )
  if (applicantIncomeDocs.length === 0) return false

  var missing = applicantIncomeDocs.filter((x) => Constants.ExpectingDocumentStatuses.indexOf(x.status) > -1)

  return missing.length === 0
}

export function hasApplicantAskedToSkipBankRequest(
  creditApp: FilteredCreditApplication,
  applicantType: ApplicantType,
  skipBankAccountRequiredDocuments: RequiredDocument[],
): boolean {
  var applicantIncomeDocs = skipBankAccountRequiredDocuments.filter((x) => x.applicantType === applicantType)

  return applicantIncomeDocs.every((r) => creditApp.requiredDocuments.findIndex((d) => d.id === r.id) > -1)
}

export function areApplicantIncomesAreUnderReview(
  creditApp: FilteredCreditApplication,
  applicantType: ApplicantType,
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[],
): boolean {
  const areIncomeDocsSubmitted = hasApplicantSubmittedAllRequiredIncomeDocuments(
    skipBankAccountRequiredDocuments,
    applicantType,
  )

  const didApplicantSubmitFlinks = hasApplicantSubmittedFlinks(creditApp, applicantType)
  const didApplicantCompleteFlinks = hasApplicantCompletedFlinks(creditApp, applicantType)
  const areIncomesConfirmed =
    applicantType === 'applicant' ? creditApp.applicant.areIncomesConfirmed : creditApp.coapplicant?.areIncomesConfirmed

  return !areIncomesConfirmed && (areIncomeDocsSubmitted || (didApplicantSubmitFlinks && !didApplicantCompleteFlinks))
}

export function areIncomesUnderReview(
  creditApp: FilteredCreditApplication,
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[],
): boolean {
  const appIncomeUnderReview = areApplicantIncomesAreUnderReview(
    creditApp,
    'applicant',
    skipBankAccountRequiredDocuments,
  )
  const coappIncomeUnderReview =
    !!creditApp.coapplicant &&
    areApplicantIncomesAreUnderReview(creditApp, 'coApplicant', skipBankAccountRequiredDocuments)

  return appIncomeUnderReview && coappIncomeUnderReview
}

export function canCompleteWorksheet(creditApp: FilteredCreditApplication): boolean {
  return creditApp.loanPurposeId === Enum.ELoanPurpose.GoodsAndServices
}

export function isWorksheetCompleted(creditApp: FilteredCreditApplication): boolean {
  return creditApp.worksheet.status === Enum.EWorksheetStatus.Active
}

export function isAwaitingContractSignature(creditApp: FilteredCreditApplication): boolean {
  return creditApp.contract.status === ContractStatusEnum.Ready
}

export function applicantFullName(applicant: FilteredApplicant): string {
  return `${applicant.firstName}\u00a0${applicant.lastName}`
}

export function applicantFlinksId(creditApp: FilteredCreditApplication | null, applicantType: ApplicantType) {
  if (creditApp) {
    var reqStep = creditApp.requiredExternalSteps.find(
      (x) =>
        x.applicantType === applicantType &&
        x.externalStepId === 'bankAccount' &&
        x.status === Enum.ERequiredExternalStepStatus.WaitingForClient,
    )

    if (reqStep) {
      return reqStep.externalServiceId
    }
  }

  return ''
}

export function hasApplicantSubmittedFlinks(creditApp: FilteredCreditApplication | null, applicantType: ApplicantType) {
  if (creditApp) {
    var reqStep = creditApp.requiredExternalSteps.find(
      (x) =>
        x.applicantType === applicantType &&
        x.externalStepId === 'bankAccount' &&
        (x.status === Enum.ERequiredExternalStepStatus.WaitingForBank ||
          x.status === Enum.ERequiredExternalStepStatus.Completed),
    )

    if (reqStep) {
      return true
    }
  }

  return false
}

export function hasApplicantCompletedFlinks(creditApp: FilteredCreditApplication | null, applicantType: ApplicantType) {
  if (creditApp) {
    var reqStep = creditApp.requiredExternalSteps.find(
      (x) =>
        x.applicantType === applicantType &&
        x.externalStepId === 'bankAccount' &&
        x.status === Enum.ERequiredExternalStepStatus.Completed,
    )

    if (reqStep) return true
  }

  return false
}

export function isDocumentMatchingRequiredDoc(document: CreditApplicationDocument, reqDoc: RequiredDocument) {
  return (
    document.typeId === reqDoc.typeId &&
    document.applicantType === reqDoc.applicantType &&
    document.subKey === reqDoc.subKey
  )
}

export function buildRequiredDocumentsWithStatus(
  requiredDocuments: RequiredDocument[] | undefined | null,
  documents: CreditApplicationDocument[] | undefined | null,
) {
  if (!requiredDocuments || !documents) return EMPTY_ARRAY

  const ret = [] as RequiredDocumentWithStatus[]
  requiredDocuments.forEach((req) => {
    const doc = documents.find((d) => isDocumentMatchingRequiredDoc(d, req))
    if (doc) ret.push({ ...req, status: doc.status, refusalReason: doc.refusalReason } as RequiredDocumentWithStatus)
    else ret.push({ ...req, status: Enum.EDocumentStatus.AwaitingDocument, refusalReason: null })
  })

  return ret
}

export function canBuyInsurance(creditApp: FilteredCreditApplication) {
  return (
    creditApp.applicant.province !== Enum.EProvince.quebec &&
    creditApp.applicant.province !== Enum.EProvince.saskatchewan
  )
}

export function hasFlexPlan(creditApp: FilteredCreditApplication) {
  return creditApp.merchantPaymentPlan?.loanTerm !== creditApp.merchantPaymentPlan?.reducedRateDurationInMonths
}
