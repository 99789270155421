import React, { LegacyRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldError } from 'react-hook-form'
import { translateErrorCode } from '@src/types'
import { TOptionsBase } from 'i18next'
import { $Dictionary } from 'i18next/typescript/helpers'

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  error?: FieldError
  label: string
  wrapStyle?: string
  errorParams?: TOptionsBase & $Dictionary
}

const InputText = React.forwardRef<Props, Props>(function (
  { id, error, label, wrapStyle, type, errorParams, ...otherProps }: Props,
  ref,
) {
  const { t } = useTranslation()
  type ??= 'text'
  return (
    <div className={`control-group ${error && 'error'}`}>
      <label htmlFor={id}>{t(label)}</label>
      <div className={wrapStyle}>
        <input id={id} type={type} {...otherProps} ref={ref as LegacyRef<HTMLInputElement>} />
      </div>
      {error && <p className="error-message">{t(translateErrorCode(error), errorParams)}</p>}
    </div>
  )
})

export default InputText
