import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Enum } from '@src/types'
import { FormatCurrency } from '@src/services/Formatter'
import { reportErrorToConsole, reportErrorToServer } from '@src/services/error-logger'
import { Prequalification, PrequalificationSchema } from './PrequalificationSchema'
import { isDefaultMerchant } from '@src/data/merchant-selectors'
import {
  useMerchantById,
  useValidatePromoCode,
  PromotionCodeDto,
  PromotionValidationResult,
} from '@src/api/merchants-api'
import { SubmitPrequalificationDto, useSubmitPrequalification } from '@src/api/credit-api'
import { AcceptTermsCheckbox, Alert, AsyncActionButton } from '@src/components'
import ReviewApplicantInfo from './ReviewApplicantInfo'

type Props = {
  gotoStep: (step: string) => void
  prequalification: Prequalification
}

function ReviewPrequalification({ prequalification, gotoStep }: Props) {
  const { t } = useTranslation()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const navigate = useNavigate()

  const [validatePromoCodeWithServer, isValidatingPromoCode, resetPromo] = useValidatePromoCode()
  const [submitPrequalification, isSubmitting] = useSubmitPrequalification()
  const [merchant] = useMerchantById(prequalification.merchantId)
  const [hasLoanAmountError, setHasLoanAmountError] = useState<boolean>(false)
  const [hasLoanPurposeError, setHasLoanPurposeError] = useState<boolean>(false)
  const [hasApplicantError, setHasApplicantError] = useState<boolean>(false)
  const [hasApplicantAddressError, setHasApplicantAddressError] = useState<boolean>(false)
  const [hasApplicantRevenueError, setHasApplicantRevenueError] = useState<boolean>(false)
  const [hasFormError, setHasFormError] = useState<boolean>(false)
  const [promoCodeInput, setPromoCodeInput] = useState<string>('')
  const [promoCodeValidation, setPromoValidation] = useState<PromotionValidationResult | null>(null)
  const isPromoCodeValidated = promoCodeValidation?.status === Enum.EPromoCodeStatus.Valid
  const shoudDisplayPromoCodeInput = !isPromoCodeValidated || promoCodeInput.length > 0
  const shouldDisplayPromoCodeReviewInfo = !shoudDisplayPromoCodeInput
  const shouldDisplayAnyPromoCodeRelatedInfo = merchant?.supportsPromotions === true
  const applicant = prequalification.applicant

  const validatePromoCodeFormat = useCallback(
    (promoCode: string) => {
      const alphanumericRegex = /^[a-zA-Z0-9]+$/

      if (!alphanumericRegex.test(promoCode)) {
        setPromoValidation(null)
        return
      }

      const promoCodeDto: PromotionCodeDto = {
        promotionCode: promoCode,
        merchantId: prequalification.merchantId,
      } as PromotionCodeDto

      validatePromoCodeWithServer(promoCodeDto).then((result) => {
        setPromoValidation(result)
        return result
      })
    },
    [prequalification.merchantId],
  )

  const getPromoError = () => {
    if (!promoCodeValidation) return ''

    if (promoCodeValidation.status === Enum.EPromoCodeStatus.Valid)
      return (
        <div className="text-success">
          {t('loanAmount.validPromo')}&nbsp;
          <i className="fa-regular fa-check" />
        </div>
      )
    if (promoCodeValidation?.status === Enum.EPromoCodeStatus.Expired)
      return <div className="error-message">{t('loanAmount.expiredPromo')}</div>
    if (promoCodeValidation?.status === Enum.EPromoCodeStatus.Invalid)
      return <div className="error-message">{t('loanAmount.invalidPromo')}</div>
    return ''
  }

  const getValidatePromotionButtonText = () => {
    if (isValidatingPromoCode)
      return (
        <>
          {t('loanAmount.validatingPromo')}
          <div className="mini-spinner" />
        </>
      )

    return t('loanAmount.validatePromo')
  }

  const onPromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCodeInput(e.target.value)
    if (promoCodeValidation && promoCodeValidation.status !== Enum.EPromoCodeStatus.Valid) setPromoValidation(null)
  }

  const merchantIsIfinanceMedicard = isDefaultMerchant(prequalification.merchantId)

  const prequalificationDataContainsErrors = async (prequalification: Prequalification, schema: any) => {
    setHasLoanAmountError(false)
    setHasLoanPurposeError(false)
    setHasApplicantError(false)
    setHasApplicantAddressError(false)
    setHasApplicantRevenueError(false)
    setHasFormError(false)
    let formHasError = false
    try {
      const validationErrors = await schema.validateSync(prequalification, { abortEarly: false })
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        let path = err.path as string
        if (path.includes('requestedLoanAmount')) {
          setHasLoanAmountError(true)
          formHasError = true
        }
        if (path.includes('loanPurposeId')) {
          setHasLoanPurposeError(true)
          formHasError = true
        }
        if (path.includes('applicant')) {
          setHasApplicantError(true)
          formHasError = true
        }
        if (path.includes('applicant.currentAddress')) {
          setHasApplicantAddressError(true)
          formHasError = true
        }
        if (path.includes('applicant.currentJobs[0]')) {
          setHasApplicantRevenueError(true)
          formHasError = true
        }
      })
    }
    if (formHasError) {
      setHasFormError(formHasError)
      window.scrollTo(0, 0)
    }
    return formHasError
  }

  const buildDtoAndSubmitPrequal = async (evt: { preventDefault: () => void }) => {
    evt.preventDefault()

    if ((await prequalificationDataContainsErrors(prequalification, PrequalificationSchema)) === false) {
      try {
        if (!executeRecaptcha) {
          reportErrorToServer(new Error('ReCAPTCHA non disponible.'))
          return
        }

        const recaptchaToken = await executeRecaptcha('submit_postCreditApplicationAndPrequalify')

        const dto: SubmitPrequalificationDto = {
          recaptchaToken,
          prequalification: {
            ...prequalification,
            merchantPaymentPlanId:
              promoCodeValidation?.status === Enum.EPromoCodeStatus.Valid
                ? promoCodeValidation.merchantPaymentPlanId
                : null,
          },
        }

        const creditApp = await submitPrequalification(dto)
        navigate(`/creditApplication/${creditApp.id}`, { replace: true })
      } catch (error: any) {
        if (error.response?.status === 424) {
          navigate('/recaptcha-validation-failed')
        } else {
          reportErrorToServer(error as Error)
        }
      }
    }
  }

  return (
    <section className="step-content">
      <h3 className="form-question">{t('review.title')}</h3>
      <div className="paragraph">
        <p>{t('review.description')}</p>
      </div>

      <form onSubmit={buildDtoAndSubmitPrequal}>
        {hasFormError && <Alert type="error" message={t('review.formContainsErrors')} />}
        <div className="review-wrap">
          <div className="review-item">
            <h1 className="h3">{t('review.desiredLoanAmount')}</h1>
            <div className="box">
              <div className="box-content">
                <p>
                  <strong>{t('loanAmount.amount')} : </strong>
                  <span>{FormatCurrency(prequalification.requestedLoanAmount)}</span>
                </p>
              </div>
              <button
                type="button"
                className={hasLoanAmountError ? 'btn-error btn-simple-error' : 'btn-action btn-simple'}
                onClick={() => gotoStep('loan-amount')}
              >
                {t('review.modify')}
              </button>
            </div>
          </div>

          {merchantIsIfinanceMedicard && (
            <div className="review-item">
              <h1 className="h3">{t('common.loanPurpose')}</h1>
              <div className="box">
                <div className="box-content">
                  <p>
                    <strong>{t('common.loanPurpose')} : </strong>
                    <span>{t(`enum.eLoanPurpose.${prequalification.loanPurposeId}`)}</span>
                  </p>
                  <p>
                    <strong>{t('common.serviceProvider')} : </strong>
                    <span>{prequalification.merchantName ? prequalification.merchantName : t('common.none')}</span>
                  </p>
                </div>
                <button
                  type="button"
                  className={hasLoanPurposeError ? 'btn-error btn-simple-error' : 'btn-action btn-simple'}
                  onClick={() => gotoStep('loan-purpose')}
                >
                  {t('review.modify')}
                </button>
              </div>
            </div>
          )}

          <ReviewApplicantInfo
            applicant={applicant}
            hasApplicantError={hasApplicantError}
            hasApplicantAddressError={hasApplicantAddressError}
            hasApplicantRevenueError={hasApplicantRevenueError}
            gotoStep={gotoStep}
          />

          {shouldDisplayAnyPromoCodeRelatedInfo && (
            <div className="review-item">
              <h1 className="h3">{t('loanAmount.promoCode')}</h1>
              <div className="box" style={{ padding: '2.5rem 3rem 2.5rem 3rem' }}>
                {shoudDisplayPromoCodeInput && (
                  <div
                    className="box-content"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                      flexWrap: 'wrap',
                    }}
                  >
                    <div className="control-group mid">
                      <label htmlFor="promoCode">{t('loanAmount.promoCode')}</label>
                      <input
                        style={{ textTransform: 'uppercase' }}
                        id="promoCode"
                        type="text"
                        value={promoCodeInput}
                        onChange={(e) => onPromoCodeChange(e)}
                        disabled={isValidatingPromoCode || isPromoCodeValidated}
                      />
                      {getPromoError()}
                    </div>
                    <div className="control-group mid">
                      <button
                        disabled={promoCodeInput.length === 0 || isValidatingPromoCode || isPromoCodeValidated}
                        type="button"
                        className="btn btn-blue"
                        onClick={() => validatePromoCodeFormat(promoCodeInput)}
                      >
                        {getValidatePromotionButtonText()}
                      </button>
                    </div>
                  </div>
                )}
                {shouldDisplayPromoCodeReviewInfo && (
                  <p>
                    <strong>{t('loanAmount.validPromo')} : </strong>
                    <span>{promoCodeInput.toUpperCase()}</span>
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        <footer className="review-footer">
          <h3>
            <Trans i18nKey={'confirmation.consentTitle'} />
          </h3>

          <AcceptTermsCheckbox
            msgOnInvalid="common.acceptConditions"
            id="acceptConditions"
            termId="confirmation.consent"
          />

          <AcceptTermsCheckbox
            msgOnInvalid="common.acceptConditions"
            id="acceptAuthorise"
            termId="common.acceptAuthorization"
          >
            <br />
            {t('confirmation.authorization')}
            <u>
              <a href={t('common.policyLink')} target="_blank" rel="noreferrer">
                {t('contact.linkText')}
              </a>
            </u>
            {t('confirmation.authorizationEnd')}
          </AcceptTermsCheckbox>
          <AsyncActionButton
            type="submit"
            className="btn btn-blue"
            disabled={isSubmitting || isValidatingPromoCode || hasFormError}
            isPending={isSubmitting}
            primary
          >
            {t('review.getQuote')}
          </AsyncActionButton>
        </footer>
      </form>
    </section>
  )
}

export default ReviewPrequalification
