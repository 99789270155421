import { useLayoutEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import TopLevelRoutes from './Routes'
import Header from './components/Header'
import { useTranslation } from 'react-i18next'
import { reportErrorToServer } from '@src/services/error-logger'
import ScrollToTop from './components/ScrollToTop'
import { AppContext, useAppStoreProvider } from '@src/data/AppContext'

export default function App(): JSX.Element {
  const { i18n } = useTranslation()

  const appStore = useAppStoreProvider()

  return (
    <AppContext.Provider value={appStore}>
      <Router>
        <ScrollToTop />
        <Header language={i18n.language}/>
        <TopLevelRoutes />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Router>
    </AppContext.Provider>
  )
}