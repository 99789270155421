import { yupResolver } from '@hookform/resolvers/yup'
import { useSubmitHardHit, SubmitHardHitDto, FilteredCreditApplication } from '@src/api/credit-api'
import { AsyncActionButton } from '@src/components'
import SinInput from '@src/components/SinInput'
import {
  EditSinAndLoanAmount,
  buildEditSinAndLoanAmountSchema,
} from '@src/containers/ViewCreditApplication/components/EditPrequalificationSchema'
import { getLoanAmount } from '@src/data/credit-application-selectors'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
  creditApp: FilteredCreditApplication
}

export default function CoappSinForm({ creditApp }: Props) {
  const { t } = useTranslation()
  const [submitCoappSin, isSubmitting] = useSubmitHardHit()

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<EditSinAndLoanAmount>({
    mode: 'onBlur',
    defaultValues: {
      needsApplicantSin: false,
      needsCoapplicantSin: true,
      requestedLoanAmount: getLoanAmount(creditApp),
      id: creditApp.id,
    },
    resolver: yupResolver(buildEditSinAndLoanAmountSchema(creditApp.prequalificationDecision.maxLoanAmount)),
  })

  const onSubmit = (data: EditSinAndLoanAmount) => {
    const dto: SubmitHardHitDto = {
      id: creditApp.id,
      coapplicantSin: data.coapplicantSin,
      requestedLoanAmount: data.requestedLoanAmount,
    }
    submitCoappSin(dto)
  }
  return (
    <main className="general-message">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>{t('prequalification.coapplicantCreditLessThan2Years')}</h3>
        <SinInput error={errors.coapplicantSin} id="coapplicantSin" {...register('coapplicantSin')} />
        <div className="btn-group">
          <AsyncActionButton type="submit" isPending={isSubmitting}>
            {t('common.submit')}
          </AsyncActionButton>
        </div>
      </form>
    </main>
  )
}
