import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AsyncActionButton from './AsyncActionButton'

type Props = {
  creditAppId: string
}

function CancelAppButton({ creditAppId }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <AsyncActionButton
      type="button"
      className="btn secondary-action-btn btn-simple"
      onClick={() => navigate(`/creditApplication/${creditAppId}/cancel`)}
    >
      <i className="fa-regular fa-ban" />
      {t('common.cancelMyApp')}
    </AsyncActionButton>
  )
}

export default CancelAppButton
