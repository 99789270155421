import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { extractNumbersFromMerchantId } from '@src/data/merchant-selectors'
import { FilteredCreditApplication } from '@src/api/credit-api'

type Props = {
  creditApp: FilteredCreditApplication
}

function ClosedApplicationTile({ creditApp }: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const merchantId = extractNumbersFromMerchantId(creditApp.merchantId)
  const message = `common.${creditApp.status}`
  const messageHasTitle = t(`common.${creditApp.status}Title`) !== `common.${creditApp.status}Title`
  const messageHeader = t(`common.${creditApp.status}Title`)
  return (
    <main className="general-message">
      <h4 className="subtitle">{t('common.financeApplication')}</h4>
      {messageHasTitle && <h1 style={{ marginBottom: '2rem' }}>{t(messageHeader)}</h1>}
      <h3>
        <p className="black-strong">
          <Trans>{t(message)}</Trans>
        </p>
      </h3>
      <div className="btn-group full-width-mobile">
        <button
          type="button"
          className="btn btn-blue primary-action-btn"
          onClick={() => {
            navigate(`/${merchantId}`, { replace: true })
          }}
        >
          {t('common.newApplication')}
          <i className="fa-regular fa-paper-plane-top" />
        </button>
      </div>
    </main>
  )
}

export default ClosedApplicationTile
