import { createContext, useContext, useMemo, useState } from 'react'
import { getIdsFromUrl } from '@src/services/query-string'

export type TAppStore = {
  merchantId: string
  setMerchantId: (id: string) => void
}

export const AppContext = createContext<TAppStore | null>(null)

export function useAppStoreProvider() {
  const idsFromUrl = getIdsFromUrl()
  const [merchantId, setMerchantId] = useState<string>(idsFromUrl.merchantId)

  return useMemo(
    () => ({
      merchantId,
      setMerchantId,
    }),
    [merchantId, setMerchantId],
  )
}

export function useAppStore(): TAppStore {
  return useContext(AppContext)!
}
