import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  toggleText: string
  children: any
}
function ExpandableSection({ toggleText, children }: Props) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <>
      <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setIsOpen(!isOpen)}>
        <i
          className={isOpen ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'}
          style={{ marginRight: '0.5rem' }}
        />
        {t(toggleText)}
      </span>
      {isOpen && children}
    </>
  )
}

export default ExpandableSection
