import React from 'react'
import { range } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { differenceInYears } from 'date-fns'
import { Constants, Enum } from '@src/types/constants'
import { removeSinFormat, sinFormat } from '@src/services/utils'
import { FORM_ID } from './StepperFormFooter'
import {
  PersonalInformationInput,
  PersonalInformationInputSchema,
} from '@src/containers/SubmitNewApp/components/PrequalificationSchema'

type Props = {
  prequalificationData: PersonalInformationInput
  isCoapplicant?: boolean
  onPrequalificationUpdated: (data: PersonalInformationInput) => void
  loanPurposeId: string
}

function PersonalInformationForm({
  isCoapplicant,
  prequalificationData,
  onPrequalificationUpdated,
  loanPurposeId,
}: Props) {
  const { t, i18n } = useTranslation()

  const [birthDate, setBirthDate] = React.useState<{ [k: string]: number | undefined }>({
    year: undefined,
    month: undefined,
    day: undefined,
  })

  function calculateAge(year: number | undefined, month: number | undefined, day: number | undefined) {
    let result = false
    if (year && month && day) {
      const datestring = `${year.toString()}-${month.toString()}-${day.toString()}`
      const date = new Date(datestring)
      const age = differenceInYears(new Date(), date)
      if (age >= 75) result = true
    }
    return result
  }

  const isOver75 = calculateAge(birthDate.year, birthDate.month, birthDate.day)
  const [daysInMonth, setDaysInMonth] = React.useState(31)
  const validationSchema = PersonalInformationInputSchema
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
    getValues,
    trigger,
  } = useForm<PersonalInformationInput>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: prequalificationData,
  })

  const watchBirthDate = watch('applicant.birthDate')
  const watchBeneficiaryTypeId = watch('beneficiaryTypeId')
  const isVeterinary = loanPurposeId === Enum.ELoanPurpose.Veterinary
  const isMedical =
    loanPurposeId === Enum.ELoanPurpose.Medical ||
    loanPurposeId === Enum.ELoanPurpose.Aesthetics ||
    loanPurposeId === Enum.ELoanPurpose.Dentistry

  React.useEffect(() => {
    const d = watchBirthDate?.split('-') ?? ['', '', '']
    if (watchBirthDate)
      setBirthDate({
        year: Number(d[0]),
        month: Number(d[1]),
        day: Number(d[2]),
      })
  }, [watchBirthDate])

  const fixNumberInTwoDigits = (num: number) => {
    return (num < 10 ? '0' : '') + num
  }

  const handleDate = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setBirthDate((birth) => {
      const { value } = event.target
      switch (event.target.id) {
        case 'month':
          birth.month = +value
          break
        case 'year':
          birth.year = +value
          break
        default:
          birth.day = +value
      }

      if (birth.year !== undefined && birth.month !== undefined) {
        const days = new Date(+birth.year, birth.month, 0).getDate()
        setDaysInMonth(days)
        if (birth.day !== undefined && birth.day > days) {
          birth.day = undefined
        }
      }

      if (!Object.values(birth).includes(undefined)) {
        setValue(
          'applicant.birthDate',
          `${birth.year}-${fixNumberInTwoDigits(Number(birth.month))}-${fixNumberInTwoDigits(Number(birth.day))}`,
        )
      } else {
        setValue('applicant.birthDate', '')
      }

      return birth
    })
  }

  const beneficiaryRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('beneficiaryTypeId', +event.target.value)
    errors.beneficiaryTypeId = undefined
  }

  const currentDate = new Date()

  const minBirthDate = {
    year: currentDate.getFullYear() - 18,
    month: currentDate.getMonth() + 1,
    day: currentDate.getDate(),
  }

  const maxBirthDate = {
    year: currentDate.getFullYear() - 100,
    month: 1,
    day: 1,
  }

  const handleSinOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const sin = removeSinFormat(value)
    setValue('applicant.sin', sin)
    trigger('applicant.sin')
  }

  React.useEffect(() => {
    if (isVeterinary || watchBeneficiaryTypeId === Enum.EBeneficiaryType.Other) {
      setValue('beneficiaryTypeId', Enum.EBeneficiaryType.Other)
    } else {
      setValue('beneficiaryTypeId', watchBeneficiaryTypeId)
      setValue('otherBeneficiaryFirstName', undefined)
      setValue('otherBeneficiaryLastName', undefined)
    }
  }, [watchBeneficiaryTypeId, isVeterinary])

  return (
    <section className="step-content">
      <h3 className="form-question">
        {isCoapplicant ? t('personalInformation.aboutCoapplicant') : t('personalInformation.aboutYourself')}
      </h3>

      <form onSubmit={handleSubmit(onPrequalificationUpdated)} id={FORM_ID}>
        <div className={`control-group ${errors.applicant?.languageId && 'error'}`}>
          <label htmlFor="radiobuttons">{t('personalInformation.preferredLanguage')}</label>
          <div className="radiobuttons-wrap">
            <div className="radio-wrap">
              <input
                type="radio"
                value={Enum.ELanguage.French}
                id="French"
                {...register('applicant.languageId')}
                defaultChecked={prequalificationData.applicant.languageId === Enum.ELanguage.French}
              />
              <label htmlFor="French">{t(`enum.ePreferredLanguage.${Enum.ELanguage.French}`)}</label>
            </div>
            <div className="radio-wrap">
              <input
                type="radio"
                value={Enum.ELanguage.English}
                id="English"
                {...register('applicant.languageId')}
                defaultChecked={prequalificationData.applicant.languageId === Enum.ELanguage.English}
              />
              <label htmlFor="English">{t(`enum.ePreferredLanguage.${Enum.ELanguage.English}`)}</label>
            </div>
          </div>
        </div>

        <div className={`control-group ${errors.applicant?.genderId && 'error'}`}>
          <label htmlFor="radiobuttons">{t('personalInformation.yourGender')}</label>
          <div className="radiobuttons-wrap">
            <div className="radio-wrap">
              <input
                type="radio"
                value={0}
                id="Male"
                {...register('applicant.genderId')}
                defaultChecked={prequalificationData.applicant.genderId === 0}
              />
              <label htmlFor="Male">{t('personalInformation.male')}</label>
            </div>
            <div className="radio-wrap">
              <input
                type="radio"
                value={1}
                id="Female"
                {...register('applicant.genderId')}
                defaultChecked={prequalificationData.applicant.genderId === 1}
              />
              <label htmlFor="Female">{t('personalInformation.female')}</label>
            </div>
            <div className="radio-wrap">
              <input
                type="radio"
                value={2}
                id="Other"
                {...register('applicant.genderId')}
                defaultChecked={prequalificationData.applicant.genderId === 2}
              />
              <label htmlFor="Other"> {t('personalInformation.preferNotSay')}</label>
            </div>
          </div>
        </div>

        <div className={`control-group mid  ${errors.applicant?.firstName && 'error'}`}>
          <label htmlFor="name">{t('personalInformation.yourFirstName')}</label>
          <input type="text" id="firstName" {...register('applicant.firstName')} maxLength={50} />
        </div>

        <div className={`control-group mid  ${errors.applicant?.lastName && 'error'}`}>
          <label htmlFor="last_name">{t('personalInformation.yourLastName')}</label>
          <input type="text" id="lastName" {...register('applicant.lastName')} maxLength={50} />
        </div>

        <div className="control-group">
          <label htmlFor="last_name">{t('personalInformation.yourBirthDate')}</label>
          <div className="date-wrap">
            <div className={`dropdown-wrap ${errors.applicant?.birthDate && !birthDate.year && 'error'}`}>
              <select id="year" name="date" onChange={handleDate} value={birthDate.year?.toString() ?? ''}>
                <option value="" disabled>
                  {t('personalInformation.birthYear')}
                </option>
                {range(minBirthDate.year, maxBirthDate.year).map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className={`dropdown-wrap ${errors.applicant?.birthDate && !birthDate.month && 'error'}`}>
              <select
                id="month"
                name="date"
                onChange={handleDate}
                value={birthDate.month ? fixNumberInTwoDigits(birthDate.month) : ''}
              >
                <option value="" disabled>
                  {t('personalInformation.birthMonth')}
                </option>
                {Object.keys(Constants.monthNames).map((month) => (
                  <option value={fixNumberInTwoDigits(+month)} key={Constants.monthNames[Number(month)].nameEn}>
                    {i18n.language === 'en'
                      ? Constants.monthNames[Number(month)].nameEn
                      : Constants.monthNames[Number(month)].nameFr}
                  </option>
                ))}
              </select>
            </div>
            <div className={`dropdown-wrap ${errors.applicant?.birthDate && !birthDate.day && 'error'}`}>
              <select
                id="day"
                name="date"
                onChange={handleDate}
                value={birthDate.day ? fixNumberInTwoDigits(birthDate.day) : ''}
              >
                <option value="" disabled>
                  {t('personalInformation.birthDay')}
                </option>
                {range(1, daysInMonth + 1).map((item) => (
                  <option key={item} value={fixNumberInTwoDigits(item)}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {isOver75 && !isCoapplicant && (
            <div
              style={{
                border: '#08abf0 solid 3px',
                padding: '15px',
                borderRadius: '8px',
                marginTop: '2rem',
                fontWeight: 'bold',
                color: '#08abf0',
              }}
            >
              <strong>{t('personalInformation.birthYearOver75Message')}</strong>
            </div>
          )}
        </div>

        <div className={`control-group  ${errors.applicant?.sin && 'error'}`}>
          <label htmlFor="insurance-number">
            {t('personalInformation.sin')}
            <span className="help-tip">
              <p>{t('personalInformation.optionalSin')}</p>
            </span>
          </label>
          <input
            id="insurance-number"
            placeholder="XXX-XXX-XXX"
            className="sin-input"
            type="text"
            defaultValue={sinFormat(getValues('applicant.sin') as string)}
            onBlur={handleSinOnChange}
          />
        </div>
        {!isCoapplicant && isVeterinary && (
          <div className={`control-group mid  ${errors.otherBeneficiaryLastName && 'error'}`}>
            <label htmlFor="otherBeneficiaryLastName">{t('loan.petName')}</label>
            <input type="text" id="otherBeneficiaryLastName" {...register('otherBeneficiaryLastName')} maxLength={50} />
          </div>
        )}
        {!isCoapplicant && isMedical && (
          <div className="control-group">
            <div id="for-whom-radiobuttons" className="radiobuttons-wrap">
              <div className="radio-wrap">
                {!isCoapplicant && (
                  <>
                    <input
                      type="radio"
                      value={Enum.EBeneficiaryType.Applicant}
                      onChange={beneficiaryRadioChange}
                      checked={watchBeneficiaryTypeId === Enum.EBeneficiaryType.Applicant}
                      id="forMe"
                    />
                    <label htmlFor="forMe">{t('loan.forMe')}</label>
                  </>
                )}

                {isCoapplicant && (
                  <>
                    <input
                      type="radio"
                      value={Enum.EBeneficiaryType.Coapplicant}
                      onChange={beneficiaryRadioChange}
                      checked={watchBeneficiaryTypeId === Enum.EBeneficiaryType.Coapplicant}
                      id="forMe"
                    />
                    <label htmlFor="forMe">{t('loan.forMe')}</label>
                  </>
                )}
              </div>
              <div className="radio-wrap">
                <input
                  type="radio"
                  value={Enum.EBeneficiaryType.Other}
                  onChange={beneficiaryRadioChange}
                  checked={watchBeneficiaryTypeId === Enum.EBeneficiaryType.Other}
                  id="forSomeOneElse"
                />
                <label htmlFor="forSomeOneElse">{t('loan.forSomeoneElse')}</label>
              </div>
            </div>
            {watchBeneficiaryTypeId === Enum.EBeneficiaryType.Other && (
              <div className="beneficiary-info" style={{ marginTop: '10px' }}>
                <div className={`control-group mid  ${errors.otherBeneficiaryFirstName && 'error'}`}>
                  <label htmlFor="otherBeneficiaryFirstName">{t('loan.otherBeneficiaryFirstName')}</label>
                  <input
                    type="text"
                    id="otherBeneficiaryFirstName"
                    {...register('otherBeneficiaryFirstName')}
                    maxLength={50}
                  />
                </div>
                <div style={{ marginTop: '10px' }}>
                  <div className={`control-group mid  ${errors.otherBeneficiaryLastName && 'error'}`}>
                    <label htmlFor="otherBeneficiaryLastName">{t('loan.otherBeneficiaryLastName')}</label>
                    <input
                      type="text"
                      id="otherBeneficiaryLastName"
                      {...register('otherBeneficiaryLastName')}
                      maxLength={50}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {isCoapplicant && (
          <div className="control-group">
            <label htmlFor="relationWithApplicant">{t('personalInformation.relationWithApplicant')}</label>
            <div className={`dropdown-wrap ${errors.applicant?.relationWithApplicant && 'error'}`}>
              <select id="relationWithApplicant" {...register('applicant.relationWithApplicant')}>
                {Object.values(Enum.ERelationToApplicant).map((item) => (
                  <option key={item} value={item}>
                    {t(`enum.eRelationToApplicant.${item}`)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </form>
    </section>
  )
}

PersonalInformationForm.defaultProps = {
  isCoapplicant: false,
}

export default PersonalInformationForm
