import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

function ValidateRecaptchaFailedPage() {
  const { t } = useTranslation()
  return (
    <main className="general-message paragraph ValidateRecaptchafaildPage">
      <section className="paragraph">
        <h1>{t('ValidateRecaptchafaildPage.title')} </h1>
        <p>{t('ValidateRecaptchafaildPage.introMessage')}</p>
        <ul>
          <li>
            {t('ValidateRecaptchafaildPage.Reasonproxy')}
            <ul>
              <li>{t('ValidateRecaptchafaildPage.ProxyRecommendationUsemonileCelurar')}</li>
              <li>{t('ValidateRecaptchafaildPage.ProxyRecommendationWaitSecuritynetwork')}</li>
            </ul>
          </li>
          <li>{t('ValidateRecaptchafaildPage.ReasonVPN')}</li>
          <li>{t('ValidateRecaptchafaildPage.ReasonPlugingsinterferaction')}</li>
        </ul>
        <Trans>
          <p>{t('ValidateRecaptchafaildPage.contactUs')}</p>
        </Trans>
        <p>{t('ValidateRecaptchafaildPage.thanksForTrust')}</p>
      </section>
    </main>
  )
}

export default ValidateRecaptchaFailedPage
