import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import { Enum } from '@src/types/constants'
import { removeDiacritics } from '@src/services/Formatter'
import { SearchMerchantDto, useSearchMerchant } from '@src/api/merchants-api'
import { IFINANCE_ID, MEDICARD_ID, isDefaultMerchant, isIFinanceMerchant } from '@src/data/merchant-selectors'
import { InputText } from '@src/components'
import { FORM_ID } from './StepperFormFooter'
import { ServiceProviderInput, ServiceProviderSchema } from './PrequalificationSchema'

type Props = {
  onPrequalificationUpdated: (data: ServiceProviderInput) => void
  prequalificationData: ServiceProviderInput
  loanPurposeId: string
}

function ServiceProviderForm({ onPrequalificationUpdated, prequalificationData, loanPurposeId }: Props) {
  const { t } = useTranslation()
  const MERCHANTS_DROP_DOWN_LIMIT = 5
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    register,
    watch,
  } = useForm<ServiceProviderInput>({
    mode: 'onBlur',
    resolver: yupResolver(ServiceProviderSchema),
    defaultValues: prequalificationData,
  })

  const merchantName = watch('merchantName')
  const selectedMerchantId = watch('merchantId')
  const hasSelectedMerchant = !isDefaultMerchant(selectedMerchantId)

  const [searchMerchantQuery, setSearchMerchantQuery] = useState<SearchMerchantDto>({
    financingProgramId: 'personal',
    serviceCategory: loanPurposeId,
    nameContains: prequalificationData.merchantId ? '' : prequalificationData.merchantName,
  })

  const defaultMerchantId = loanPurposeId === Enum.ELoanPurpose.Medical ? MEDICARD_ID : IFINANCE_ID

  const [merchants, isLoadingMerchants] = useSearchMerchant(searchMerchantQuery)
  const excludedWords = [
    '.',
    ',',
    ':',
    'le',
    'la',
    'les',
    'de',
    'du',
    'des',
    'au',
    'aux',
    'dans',
    'par',
    'pour',
    'sur',
    'the',
    'one',
    'and',
    'on',
    'of',
    'a',
    'dr.',
    'dr',
  ]

  const getRelevantWordsFromQuery = useCallback((queryString: string) => {
    const words = queryString.split(' ')
    return words.filter((word) => !excludedWords.includes(word))
  }, [])

  const setSearchName = useCallback(
    (name: string) => {
      setSearchMerchantQuery((prev: SearchMerchantDto) => {
        return { ...prev, nameContains: name }
      })
    },
    [setSearchMerchantQuery],
  )

  const deboucedUpdateSearchName = useCallback(debounce(setSearchName, 1000), [])
  const onMerchantNameChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('merchantName', e.target.value)
    setValue('merchantId', defaultMerchantId)
    setValue('loanPurposeId', loanPurposeId)
    deboucedUpdateSearchName(e.target.value)
  }, [])

  const skipMerchantSelection = useCallback(() => {
    const form = getValues()
    onPrequalificationUpdated(form)
  }, [])

  const bottomRef = useRef<HTMLDivElement | null>(null)

  const isIfinanceMerchant = isIFinanceMerchant(defaultMerchantId)

  const loanPurposeClinic = [
    Enum.ELoanPurpose.Medical,
    Enum.ELoanPurpose.Aesthetics,
    Enum.ELoanPurpose.Dentistry,
    Enum.ELoanPurpose.Veterinary,
  ]

  const requiresSpecialistName = loanPurposeClinic.indexOf(loanPurposeId) !== -1

  const handleMerchantSelectedFromList = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const id = e.currentTarget.id
      const clickedMerchant = merchants.find((m) => m.id === id)
      if (clickedMerchant) {
        setValue('merchantName', clickedMerchant.name)
        setValue('merchantId', clickedMerchant.id)
        setValue('loanPurposeId', clickedMerchant.serviceCategory)
      }
    },
    [merchants],
  )

  return (
    <section className="step-content">
      <h3 className="form-question">{t('serviceProvider.provider')}</h3>

      {isIfinanceMerchant && (
        <div className="paragraph">
          <p>{t('serviceProvider.providerSelection')}</p>
        </div>
      )}

      <form onSubmit={handleSubmit(onPrequalificationUpdated)} id={FORM_ID}>
        <div className={`control-group ${requiresSpecialistName && 'mid'} upper ${errors.merchantName && 'error'}`}>
          <label htmlFor="name">
            {requiresSpecialistName ? t('serviceProvider.providerName') : t('serviceProvider.merchantName')}
          </label>
          <div className="input-with-icon">
            {selectedMerchantId && selectedMerchantId !== defaultMerchantId && <i className="fa fa-check icon" />}
            {isLoadingMerchants && <i className="fa fa-spinner fa-spin icon" style={{ top: '35%' }} />}
            <input
              type="text"
              id="merchantName"
              maxLength={100}
              onChange={onMerchantNameChanged}
              value={merchantName}
              autoComplete="off"
            />
          </div>
          {merchants && merchants.length > 0 && (
            <div className="control-group center" style={{ padding: '0' }}>
              <ul className="merchant-list" style={{ cursor: 'pointer' }}>
                {!hasSelectedMerchant &&
                  merchants.slice(0, MERCHANTS_DROP_DOWN_LIMIT).map((merchant) => {
                    const queryWords = getRelevantWordsFromQuery(merchantName)
                    const parts = merchant.name.split(' ')

                    return (
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
                      <li key={merchant.id} onClick={handleMerchantSelectedFromList} id={merchant.id}>
                        <div className="merchant-list-item">
                          <h3>
                            {parts.map((part, index) => {
                              const queryWordsContainsPart = queryWords.some((word) =>
                                removeDiacritics(part).toLowerCase().includes(removeDiacritics(word).toLowerCase()),
                              )
                              if (queryWordsContainsPart) {
                                return (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <span key={index} style={{ color: '#505050' }}>
                                    {`${part} `}
                                  </span>
                                )
                              }
                              // eslint-disable-next-line react/no-array-index-key
                              return <span key={index}>{`${part} `}</span>
                            })}
                          </h3>
                          <p style={{ fontSize: '1.25rem', paddingTop: '0.5rem' }}>{merchant.address}</p>
                        </div>
                      </li>
                    )
                  })}
              </ul>
              {!hasSelectedMerchant && merchants.length > MERCHANTS_DROP_DOWN_LIMIT && (
                <div className="merchant-list-footer" onClick={skipMerchantSelection}>
                  <h3 style={{ fontSize: '1.75rem' }}>
                    <Trans
                      i18nKey="serviceProvider.serviceProviderSearch.resultsFound"
                      values={{
                        merchantsFound:
                          merchants.length > MERCHANTS_DROP_DOWN_LIMIT ? MERCHANTS_DROP_DOWN_LIMIT : merchants.length,
                      }}
                    />
                  </h3>
                  <p style={{ fontSize: '1.5rem', marginTop: '1rem', cursor: 'pointer' }}>
                    <Trans i18nKey="serviceProvider.serviceProviderSearch.refineYourSearchOrContinue" />
                  </p>
                </div>
              )}
              {!hasSelectedMerchant && merchants.length > 0 && merchants.length <= MERCHANTS_DROP_DOWN_LIMIT && (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
                <div className="merchant-list-footer" onClick={skipMerchantSelection}>
                  <h3 style={{ fontSize: '1.75rem' }}>
                    {t('serviceProvider.serviceProviderSearch.continueWithoutSelection')}
                  </h3>
                </div>
              )}
            </div>
          )}
        </div>

        {requiresSpecialistName && (
          <InputText
            label={
              loanPurposeId === Enum.ELoanPurpose.Medical || loanPurposeId === Enum.ELoanPurpose.Aesthetics
                ? t('serviceProvider.specialistOrDoctor')
                : t('serviceProvider.specialistName')
            }
            id="specialistName"
            maxLength={50}
            error={errors.specialistName}
            {...register('specialistName')}
          />
        )}

        <div ref={bottomRef}> </div>
      </form>
    </section>
  )
}

export default ServiceProviderForm
