import ReactPixel from 'react-facebook-pixel'

export function initializeFPixel(): void {
  const USED_FPIXEL_MEASURE_ID: string | undefined = window.location.href.includes('ifinancecanada')
    ? process.env.REACT_APP_IFI_FPIXEL_MEASURE_ID
    : process.env.REACT_APP_MED_FPIXEL_MEASURE_ID
  // eslint-disable-next-line no-console
  if (!USED_FPIXEL_MEASURE_ID) console.log('FPixel init failed')
  else {
    ReactPixel.init(USED_FPIXEL_MEASURE_ID)
    ReactPixel.pageView()
  }
}
