import React, { useCallback, useLayoutEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PersonalInformationForm from './components/PersonalInformationForm'
import ContactInformationForm from './components/ContactInformationForm'
import AddressForm from './components/AddressForm'
import EmploymentForm from './components/EmploymentForm'
import StepperFormFooter from './components/StepperFormFooter'
import { buildDefaultCoapplicant, useStepListForApplicant } from './submit-app-hooks'
import StepProgressionBar from './components/StepProgressionBar'
import { scrollToTop } from '@src/services/utils'
import ReviewCoapplicant from './components/ReviewCoapplicant'
import { useGetCreditApplicationById } from '@src/api/credit-api'
import { Loader } from '@src/components'
import { Applicant } from './components/PrequalificationSchema'
import { useTranslation } from 'react-i18next'

function AddCoapplicantPage() {
  const urlParams = useParams()
  const id = urlParams.id as string
  const currentStep = urlParams.currentStep as string
  const navigate = useNavigate()
  const { t } = useTranslation()

  const steps = useStepListForApplicant()
  const currentStepId = steps.indexOf(currentStep) + 1
  const cls = `form-section step-${currentStepId}-active`

  const [creditApp, isLoadingCreditApp] = useGetCreditApplicationById(id, false)

  const [prequalInfo, setPrequalInfo] = useState(buildDefaultCoapplicant())
  const [disableNext, setDisableNext] = useState(false)

  const gotoStep = useCallback((stepPath: string) => {
    scrollToTop()
    navigate(`/creditapplication/${id}/addcoapplicant/${stepPath}`)
  }, [])

  const nextStepAfter = useCallback(
    (currentStep: string) => {
      const nextStepIdx = steps.indexOf(currentStep.toLowerCase()) + 1
      if (nextStepIdx < steps.length) {
        gotoStep(steps[nextStepIdx])
      }
    },
    [steps],
  )

  const handlePrequalificationUpdated = (data: any) => {
    const update = { ...prequalInfo, ...data }
    setPrequalInfo(update)
    nextStepAfter(currentStep)
  }
  const handleApplicantUpdated = (data: Partial<Applicant>) => {
    const update = { ...prequalInfo, applicant: { ...prequalInfo.applicant, ...data } }
    setPrequalInfo(update)
    nextStepAfter(currentStep)
  }

  useLayoutEffect(scrollToTop, [])

  if (isLoadingCreditApp) return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />
  return (
    <main id="add-coapplicant-form" className={cls}>
      <>
        <StepProgressionBar steps={steps} currentStep={currentStep} isCoapplicant />
        {currentStep == 'personal-information' && (
          <PersonalInformationForm
            isCoapplicant
            prequalificationData={prequalInfo}
            onPrequalificationUpdated={handlePrequalificationUpdated}
            loanPurposeId={prequalInfo.loanPurposeId}
          />
        )}

        {currentStep == 'contact' && (
          <ContactInformationForm
            onApplicantUpdated={handleApplicantUpdated}
            applicantData={prequalInfo.applicant}
            prohibedPhone={creditApp?.applicant.cellPhone}
          />
        )}

        {currentStep == 'address' && (
          <AddressForm
            applicantData={prequalInfo.applicant}
            onApplicantUpdated={handleApplicantUpdated}
            applicantName={prequalInfo.applicant.firstName}
            setDisableNext={setDisableNext}
          />
        )}

        {currentStep == 'employment' && (
          <EmploymentForm applicantData={prequalInfo.applicant} onApplicantUpdated={handleApplicantUpdated} />
        )}

        {currentStep == 'review' && (
          <ReviewCoapplicant gotoStep={gotoStep} prequalification={prequalInfo} creditApplicationId={id} />
        )}

        {currentStep != 'review' && (
          <StepperFormFooter
            currentStepIndex={steps.indexOf(currentStep) + 1}
            stepLength={steps.length}
            disableBack={false}
            disableNext={disableNext}
          />
        )}
      </>
    </main>
  )
}

export default AddCoapplicantPage
