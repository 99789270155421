import { FilteredCreditApplication } from '@src/api/credit-api'
import { CustomerFeedback } from '@src/api/feedback-api'
import { Enum, RequiredDocumentWithStatus } from '@src/types'
import { CreditStepStatus } from './OverviewPage/components/CreditStep'
import {
  hasApplicantSubmittedAllRequiredIncomeDocuments,
  hasApplicantSubmittedFlinks,
} from '@src/data/credit-application-selectors'

export function hasCompletedFlinks(creditApp: FilteredCreditApplication | null): boolean {
  const found = creditApp?.requiredExternalSteps?.find(
    (x) => x.applicantType === Enum.EApplicantType.Applicant && x.externalStepId === 'bankAccount',
  )

  if (found) {
    return (
      found.status === Enum.ERequiredExternalStepStatus.WaitingForBank ||
      found.status === Enum.ERequiredExternalStepStatus.Completed
    )
  }
  return false
}

export function isReadyForCustomerFeedback(
  creditApp: FilteredCreditApplication | null,
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[],
): boolean {
  if (!creditApp) return false
  return (
    creditApp.allIncomesConfirmed ||
    hasApplicantSubmittedFlinks(creditApp, 'applicant') ||
    hasApplicantSubmittedAllRequiredIncomeDocuments(skipBankAccountRequiredDocuments, 'applicant')
  )
}

export function getFeedbackStepStatus(
  creditApp: FilteredCreditApplication | null,
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[],
  feedback: CustomerFeedback | null,
) {
  if (!isReadyForCustomerFeedback(creditApp, skipBankAccountRequiredDocuments)) return CreditStepStatus.NotReady
  if (!feedback) return CreditStepStatus.RequiresAttention
  return CreditStepStatus.Completed
}
