import React from 'react'

type Props = {
  id: string
  text: string
  selected: string
  onChange: (id: string) => void
}

function FeedbackOption({ id, text, selected, onChange }: Props) {
  return (
    <div className="radiobuttons-stacked-card-item">
      <input
        required
        type="radio"
        id={`feedbackType${id}`}
        name="feedbackType"
        checked={selected === id}
        onChange={() => onChange(id)}
      />
      <label htmlFor={`feedbackType${id}`} style={{ lineHeight: '1' }}>
        {text}
      </label>
    </div>
  )
}

export default FeedbackOption
