import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  id: string
  termId: string
  msgOnInvalid: string
  children?: ReactNode
}

function AcceptTermsCheckbox({ id, children, termId, msgOnInvalid }: Props) {
  const { t } = useTranslation()

  return (
    <div className="control-group">
      <input
        required
        type="checkbox"
        id={id}
        onChange={(e) => {
          if (e.currentTarget.checked === true) e.currentTarget.setCustomValidity('')
        }}
        onInvalid={(e) => e.currentTarget.setCustomValidity(t(msgOnInvalid))}
      />
      <label style={{ fontSize: '1.8rem', fontWeight: 'inherit' }} htmlFor={id}>
        {termId && t(termId)}
        {children}
      </label>
    </div>
  )
}

export default AcceptTermsCheckbox
