import React, { LegacyRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldError } from 'react-hook-form'
import { translateErrorCode } from '@src/types'
import { TOptionsBase } from 'i18next'
import { $Dictionary } from 'i18next/typescript/helpers'

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
  error?: FieldError
  label: string
  wrapStyle?: string
  errorParams?: TOptionsBase & $Dictionary
  optionsFromEnum?: object
  optionsRootLabel?: string
  hasEmptyValue?: boolean
  disableEmptyValue?: boolean
}

const SelectInput = React.forwardRef<Props, Props>(function (
  {
    id,
    error,
    label,
    wrapStyle,
    type,
    errorParams,
    optionsFromEnum,
    optionsRootLabel,
    hasEmptyValue,
    disableEmptyValue,
    ...otherProps
  }: Props,
  ref,
) {
  const { t } = useTranslation()

  const options = useMemo(() => {
    if (optionsFromEnum && optionsRootLabel) {
      return Object.values(optionsFromEnum).map((item) => (
        <option key={item} value={item}>
          {t(`${optionsRootLabel}.${item}`)}
        </option>
      ))
    }
    return null
  }, [optionsFromEnum, optionsRootLabel, t])

  return (
    <div className={`control-group ${error && 'error'}`}>
      <label htmlFor={id}>{t(label)}</label>
      <div className={`dropdown-wrap  ${error && 'error'}`}>
        <select id={id} {...otherProps} ref={ref as LegacyRef<HTMLSelectElement>}>
          {hasEmptyValue && (
            <option value="" disabled={disableEmptyValue}>
              {' '}
            </option>
          )}
          {options}
        </select>
        {error && <p className="error-message">{t(translateErrorCode(error), errorParams)}</p>}
      </div>
    </div>
  )
})

export default SelectInput
