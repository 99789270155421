import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import App from './containers/App/App'
import './index.css'
import './containers/App/App.css'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initializeAppInsights } from './services/AppInsights'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { setupTracking } from './services/error-logger'
import { initializeGAnalytics } from './GAnalytics'
import { initializeFPixel } from './FPixel'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const ai = initializeAppInsights()
setupTracking(ai)

initializeGAnalytics()

initializeFPixel()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})
const container = document.getElementById('root')
const root = createRoot(container!)

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: 'fr',
    returnNull: false,
    debug: false,
    supportedLngs: ['fr', 'en'],
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    i18n.changeLanguage(i18n.language)
  })
  .then(() => {
    root.render(
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY || ''}>
        <I18nextProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>
            <App />
            <div
              style={{
                fontSize: '24px',
              }}
            >
              <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-right" />
            </div>
          </QueryClientProvider>
        </I18nextProvider>
      </GoogleReCaptchaProvider>,
    )
  })
export default i18n
