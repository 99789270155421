import { FilteredWorksheet } from './FilteredWorksheet'
import { ApplicantType, EApplicationStep } from '@src/types'

export type RequiredExternalStep = {
  id: string
  applicantType: ApplicantType
  externalServiceId: string
  externalStepId: string
  status: string
  extraProperties: Record<string, string>
}

export type RequiredDocument = {
  id: string
  applicantType: ApplicantType
  typeId: number
  nameFr: string
  nameEn: string
  note: string
  subKey: string
  requiredBeforeStep: EApplicationStep
}

export type CreditApplicationDocument = {
  applicantType: ApplicantType
  status: string
  typeId: number
  refusalReason: string | null
  subKey: string | null
}

export type PrequalificationDecision = {
  decision: string
  minInterestRate: number
  maxInterestRate: number
  maxLoanAmount: number
}
export type NormsDecision = {
  decision: string
  interestRate: number
  maxPmtAmount: number
  maxTermDuration: number
  maxAmountFinanced: number
}

export type FinalDecision = {
  decision: string
  interestRate: number
  maxPmtAmount: number
  maxTermDuration: number
  maxAmountFinanced: number
}

export type MerchantPaymentPlan = {
  merchantFeeRate: number
  borrowerFeeRate: number
  reducedInterestRate: number
  interestRate: number
  reducedRateDurationInMonths: number
  loanTerm: number
}

export type FilteredApplicant = {
  firstName: string
  lastName: string
  middleName: string
  province: string
  cellPhone: string
  homePhone: string
  age: number
  hasSin: boolean
  hasCreditReport: boolean
  hasLockedCreditReport: boolean
  isCreditTwoYearsOrLess: boolean
  areIncomesConfirmed: boolean
  areFlinksIncomeMatchingDeclared: boolean | null
  flinksHasEmploymentOrSocialInsuranceIncome: boolean | null
  canContinueWithComputedIncome: boolean | null
  hasSkippedIncomeValidation: boolean
}

export enum ContractStatusEnum {
  Ready = 'ready',
  Sending = 'sending',
  Declined = 'declined',
  NotReady = 'notready',
  UnderReview = 'review',
  Completed = 'completed',
}

export type Contract = {
  status: ContractStatusEnum
  declinedReason: string | null
}

export type FilteredCreditApplication = {
  id: string
  referenceNumber: number
  status: string
  applicant: FilteredApplicant
  coapplicant: FilteredApplicant | null
  loanPurposeId: string
  requiredDocuments: RequiredDocument[]
  documents: CreditApplicationDocument[]
  prequalificationDecision: PrequalificationDecision
  normsDecision: NormsDecision
  finalDecision: FinalDecision
  merchantPaymentPlan: MerchantPaymentPlan
  requestedLoanAmount: number
  worksheet: FilteredWorksheet
  contract: Contract
  merchantId: string
  merchantPaymentPlanId: string
  allIncomesConfirmed: boolean
  requiredExternalSteps: RequiredExternalStep[]
  completedVerifyAccountName: boolean
  loanCreated: boolean
  applicantWantsToCancel: boolean
  consentSoftHit: boolean
  consentHardHit: boolean
  updatedOn: Date
}
