import React from 'react'
import { useTranslation } from 'react-i18next'
import AsyncActionButton from './AsyncActionButton'
import { useRemoveCoapplicant } from '@src/api/credit-api'

type Props = {
  creditAppId: string
}

function RemoveCoappButton({ creditAppId }: Props) {
  const { t } = useTranslation()
  const [submitRemoveCoapp, isRemovingCoapp] = useRemoveCoapplicant()

  const onClick = () => submitRemoveCoapp(creditAppId)

  return (
    <AsyncActionButton
      type="button"
      withConfirm
      onClick={onClick}
      isPending={isRemovingCoapp}
      className="btn secondary-action-btn"
    >
      <i className="fa-regular fa-user-minus" />
      {t('common.removeCoapplicant')}
    </AsyncActionButton>
  )
}

export default RemoveCoappButton
