import { FilteredCreditApplication } from '@src/api/credit-api'
import {
  areApplicantIncomesAreUnderReview,
  hasMissingDocuments,
  isAwaitingContractSignature,
} from '@src/data/credit-application-selectors'
import { useTranslation } from 'react-i18next'
import CreditStep, { CreditStepStatus } from './CreditStep'
import { RequiredDocumentWithStatus } from '@src/types'

type ContractCreditStepProps = {
  creditApp: FilteredCreditApplication
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[]
}
export default function ContractCreditStep({ creditApp, skipBankAccountRequiredDocuments }: ContractCreditStepProps) {
  const { t } = useTranslation()
  const isContractReady = isAwaitingContractSignature(creditApp)
  const docStepDone = !hasMissingDocuments(creditApp)

  const applicantIncomeStepDone =
    areApplicantIncomesAreUnderReview(creditApp, 'applicant', skipBankAccountRequiredDocuments) ||
    creditApp.applicant.areIncomesConfirmed

  const coapplicantIncomeStepDone =
    creditApp.coapplicant === null ||
    creditApp.coapplicant.areIncomesConfirmed ||
    areApplicantIncomesAreUnderReview(creditApp, 'coApplicant', skipBankAccountRequiredDocuments)

  let content = ''
  let status = isContractReady ? CreditStepStatus.RequiresAttention : CreditStepStatus.NotReady
  if (!isContractReady && docStepDone && applicantIncomeStepDone && coapplicantIncomeStepDone) {
    status = CreditStepStatus.Await
    content = t('overview.contractWait')
  } else if (isContractReady) content = t('overview.contractSent')

  return (
    <CreditStep
      status={status}
      hideLinkToDetail
      linkToDetail="contract"
      title={t('overview.contractStep')}
      content={content}
    />
  )
}
