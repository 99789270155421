import React from 'react'
import { Routes, Route, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import RefinancePage from '../Refinance/RefinancePage'
import ErrorPage from '../ErrorPage'
import { reportErrorToServer } from '../../services/error-logger'
import WelcomePage from '../Welcome/WelcomePage'
import SubmitNewAppPage from '../SubmitNewApp/SubmitNewAppPage'
import RouteToStatePage from '../ViewCreditApplication/RouteToStatePage'
import AddCoapplicantPage from '../SubmitNewApp/AddCoapplicantPage'
import CancelApplicationPage from '../CancelApplication/CancelApplicationPage'
import BadCustomerReviewPage from '../BadCustomerReview/BadCustomerReviewPage'
import FlinksPage from '../Flinks/FlinksPage'
import TermsAndConditionFlinksPage from '../Flinks/TermsAndConditionFlinksPage'
import FlinksIncomeValidationPage from '../Flinks/FlinksIncomeValidationPage'
import SelectTermPage from '../SelectTerm/SelectTermPage'
import ValidateRecaptchaFailedPage from '../ValidateRecaptchaFailedPage/ValidateRecaptchaFailedPage'
import MerchantMissingPage from '../MerchantMissingPage'

export default function TopLevelRoutes(): JSX.Element {
  const { t, i18n } = useTranslation()
  const [searchParams] = useSearchParams()
  const lang = searchParams.get('lang')

  React.useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang).catch(reportErrorToServer)
    }
  }, [lang])

  return (
    <Routes>
      <Route path="/" element={<WelcomePage />} />
      <Route path="/:merchantId/" element={<WelcomePage />} />
      <Route path="/:merchantId/new-app/:currentStep" element={<SubmitNewAppPage />} />
      <Route path="/refinancingCreditApplication/:id" element={<RefinancePage />} />
      <Route path="/creditapplication/:id/addcoapplicant/:currentStep" element={<AddCoapplicantPage />} />
      <Route path="/creditapplication/:id/flinks/:applicantType" element={<FlinksPage />} />
      <Route path="/creditapplication/:id/flinks/:applicantType/terms" element={<TermsAndConditionFlinksPage />} />
      <Route path="/creditapplication/:id/flinks/:applicantType/validate" element={<FlinksIncomeValidationPage />} />
      <Route path="/creditapplication/:id/cancel" element={<CancelApplicationPage />} />
      <Route path="/recaptcha-validation-failed/*" element={<ValidateRecaptchaFailedPage />} />
      <Route path="/creditapplication/:id/badreview" element={<BadCustomerReviewPage />} />
      <Route path="/creditapplication/:id/select-term" element={<SelectTermPage />} />
      <Route path="/creditapplication/:id/*" element={<RouteToStatePage />} />
      <Route
        path="/get-merchant-error/not-found"
        element={<MerchantMissingPage reason='merchantNotFound' />}
      />
      <Route
        path="/get-merchant-error/inactive"
        element={<MerchantMissingPage reason='merchantInactive' />}
      />

      <Route path="/*" element={<ErrorPage title={t('common.notFound')} />} />
    </Routes>
  )
}
