import React, { useLayoutEffect } from 'react'
import { FilteredCreditApplication } from '@src/api/credit-api'
import DocumentsPage from './components/DocumentsPage'
import { RequiredDocumentWithStatus } from '@src/types'
import CreditSummaryPage from './components/CreditSummaryPage'
import { Route, Routes } from 'react-router-dom'
import OverviewPage from './OverviewPage/OverviewPage'
import ApplicantIncomePage from './components/ApplicantIncomePage'
import CustomerFeedbackPage from './components/CustomerFeedbackPage'
import { scrollToTop } from '@src/services/utils'
type Props = {
  creditApp: FilteredCreditApplication
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[]
}

export default function HardHitApprovedResultPage({ creditApp, skipBankAccountRequiredDocuments }: Props) {
  useLayoutEffect(scrollToTop, [])

  return (
    <Routes>
      <Route
        index
        path=""
        element={
          <OverviewPage creditApp={creditApp} skipBankAccountRequiredDocuments={skipBankAccountRequiredDocuments} />
        }
      />
      <Route path="credit-summary" element={<CreditSummaryPage creditApp={creditApp} />} />
      <Route
        path="applicant-incomes"
        element={
          <ApplicantIncomePage
            creditApp={creditApp}
            applicantType="applicant"
            skipBankAccountRequiredDocuments={skipBankAccountRequiredDocuments}
          />
        }
      />
      <Route
        path="coapplicant-incomes"
        element={
          <ApplicantIncomePage
            creditApp={creditApp}
            applicantType="coApplicant"
            skipBankAccountRequiredDocuments={skipBankAccountRequiredDocuments}
          />
        }
      />
      <Route path="required-documents" element={<DocumentsPage creditApp={creditApp} />} />
      <Route path="feed-back" element={<CustomerFeedbackPage creditApp={creditApp} />} />
    </Routes>
  )
}
