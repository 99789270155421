import React from 'react'

type Props = { type: 'error' | 'warn'; message: string }

function Alert({ type, message }: Props) {
  return (
    <div className={`alert ${type}`}>
      <i className="fa-solid fa-hexagon-exclamation" style={{ marginRight: 5 }} />
      {message}
    </div>
  )
}

export default Alert
