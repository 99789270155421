import { FilteredCreditApplication } from '@src/api/credit-api'
import {
  hasApplicantCompletedFlinks,
  hasApplicantSubmittedFlinks,
  hasApplicantSubmittedAllRequiredIncomeDocuments,
  applicantFullName,
} from '@src/data/credit-application-selectors'
import { ApplicantType, RequiredDocumentWithStatus } from '@src/types'
import CreditStep, { CreditStepStatus } from './CreditStep'
import { useTranslation } from 'react-i18next'

type IncomeCreditStepProps = {
  creditApp: FilteredCreditApplication
  applicantType: ApplicantType
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[]
}

export default function IncomeCreditStep({
  creditApp,
  applicantType,
  skipBankAccountRequiredDocuments,
}: IncomeCreditStepProps) {
  const { t } = useTranslation()
  const applicant = applicantType === 'applicant' ? creditApp.applicant : creditApp.coapplicant!
  const title = t('overview.incomeStep', { name: applicantFullName(applicant) })

  const hasApplicantFinishedFlinks = hasApplicantCompletedFlinks(creditApp, applicantType)
  const hasApplicantSubmittedFlinksRequest = hasApplicantSubmittedFlinks(creditApp, applicantType)

  const areIncomeDocsSubmitted = hasApplicantSubmittedAllRequiredIncomeDocuments(
    skipBankAccountRequiredDocuments,
    applicantType,
  )
  const areIncomePendingReview =
    !applicant.areIncomesConfirmed &&
    (areIncomeDocsSubmitted || (hasApplicantSubmittedFlinksRequest && !hasApplicantFinishedFlinks))

  const status =
    applicant.areIncomesConfirmed || areIncomePendingReview
      ? CreditStepStatus.Completed
      : CreditStepStatus.RequiresAttention

  const description = applicant.hasSkippedIncomeValidation
    ? 'overview.incomeSkipped'
    : applicant.areIncomesConfirmed
      ? 'overview.incomeConfirmed'
      : areIncomePendingReview
        ? 'overview.incomeReview'
        : 'overview.incomeVerify'

  return (
    <CreditStep
      status={status}
      linkToDetail={`${applicantType.toLowerCase()}-incomes`}
      hideLinkToDetail={status === CreditStepStatus.Completed}
      title={title}
      content={t(description)}
    />
  )
}
