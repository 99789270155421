import React, { Dispatch } from 'react'
import { useTranslation } from 'react-i18next'
import { ApplicantType, Constants } from '@src/types'
import { useUploadFiles } from '@src/api/credit-api'
import { reportErrorToConsole, reportErrorToServer } from '@src/services/error-logger'
import { UploadFilesDto } from '@src/api/upload-files-api'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { CustomError } from './RequiredDocumentTable'

type Props = {
  applicantType: ApplicantType
  typeId: number
  subKey: string | null
  creditApplicationId: string
  disabled?: boolean
  id: string
  setError: Dispatch<any>
}

function UploadFileButton({ applicantType, creditApplicationId, typeId, subKey, disabled, id, setError }: Props) {
  const { t } = useTranslation()
  const MAX_FILE_SIZE = 10485760 // 10MB
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [uploadFile, isUploading, reset] = useUploadFiles()

  const icon = isUploading ? <div className="mini-spinner-white" /> : <i className="fa-solid fa-arrow-up" />

  const onChangeFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    if (!files || files.length === 0) return

    try {
      if (!executeRecaptcha) {
        reportErrorToConsole('ReCAPTCHA non disponible.')
        return
      }
      const recaptcha = await executeRecaptcha('submit_document')
      const dto: UploadFilesDto = {
        creditApplicationId,
        applicantType,
        typeId,
        subKey,
        files: Array.from(files),
        recaptcha,
      }

      for (const file of Array.from(files)) {
        if (file.size > MAX_FILE_SIZE) {
          const customError: CustomError = new Error(t(`common.errors.fileSizeTooBig`))
          customError.id = id
          setError(customError)
          return
        }
        const fileNameSplit = file.name.split('.')
        const supportedExtention = Constants.SupportedDocumentTypes.includes(fileNameSplit[fileNameSplit.length - 1])
        if (!supportedExtention) {
          const customError: CustomError = new Error(t(`common.errors.fileTypeNotSupported`))
          customError.id = id
          setError(customError)
          return
        }
      }

      await uploadFile(dto)
      reset()
    } catch (error) {
      reportErrorToServer(error as Error)
    }
  }

  return (
    <label className="icon-btn" htmlFor={id}>
      <span style={{ color: 'white', padding: '6px' }}>{icon}</span>
      <input
        id={id}
        style={{ display: 'none' }}
        type="file"
        accept={Constants.SupportedDocumentTypes}
        onChange={onChangeFileSelected}
        disabled={isUploading || disabled}
        multiple
      />
    </label>
  )
}
export default UploadFileButton
