import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ConsentEn from './ConsentEn'
import ConsentFr from './ConsentFr'
import BackButton from '@src/components/BackButton'

function TermsAndConditionFlinksPage(): JSX.Element {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  return (
    <main className="general-message">
      {i18n.language === 'fr' && <ConsentFr />}
      {i18n.language === 'en' && <ConsentEn />}
      <BackButton />
    </main>
  )
}

export default TermsAndConditionFlinksPage
