import { useCompleteApplicantFlinks, useGetCreditApplicationById } from '@src/api/credit-api'
import { Loader } from '@src/components'
import { applicantFlinksId } from '@src/data/credit-application-selectors'
import { ApplicantType, EFlinksEventSteps } from '@src/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

function FlinksIncomeValidationPage(): JSX.Element {
  const params = useParams()
  const id = params.id as string
  const applicantType = params.applicantType as ApplicantType
  const { t, i18n } = useTranslation()
  const [completeFlinks, isCompletingFlinks] = useCompleteApplicantFlinks()
  const [creditApp, isLoadingApp] = useGetCreditApplicationById(id, false)
  const [isInProgress, setIsInProgress] = useState(true)
  const externalServiceId = applicantFlinksId(creditApp, applicantType)
  const navigate = useNavigate()
  const { innerHeight: height } = window

  useEffect(() => {
    window.addEventListener('message', function (e) {
      if (e.data?.step) {
        // The request is done, we are receiving the answer
        if (e.data.step === EFlinksEventSteps.REDIRECT) {
          setIsInProgress(false)
          const completeFlinksDto = {
            id,
            externalServiceId,
            applicantType,
          }

          completeFlinks(completeFlinksDto)
        }
      }
    })
  }, [])

  if (isLoadingApp) return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />

  const iframeUrl =
    process.env.REACT_APP_FLINKS_IFRAME +
    `?theme=light
  &desktopLayout=true
  &customerName=IFinance
  &institutionFilterEnable=true
  &demo=${process.env.REACT_APP_USE_DEMO_FLINKS ?? 'false'}
  &consentEnable=true
  &accountSelectorEnable=true
  &daysOfTransactions=Days365
  &monthsOfStatements=Months12
  &enhancedMFA=true
  &staticLoadingEnable=true
  &tag=${process.env.REACT_APP_ENV}_${externalServiceId}
  &language=${i18n.language}
  &scheduleRefresh=false`
      .replace(/ /g, '')
      .replace(/(\r\n|\n|\r)/gm, '')

  return (
    <>
      {isInProgress && (
        <main id="flinks-validation" className="general-message">
          <iframe title="flinksIFrame" height={height - 100 + ' px'} width="100%" loading="eager" src={iframeUrl} />
        </main>
      )}
      {isCompletingFlinks && <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />}
      {!isInProgress && (
        <main id="flinks-validation" className="general-message">
          <h1 style={{ marginBottom: '1rem' }}>{t('flinks.thankYou')}</h1>
          <h3>{t('flinks.accountRetrieved')}</h3>
          <h3>{t('flinks.processingInfomation')}</h3>
          <div className="btn-group full-width-mobile">
            <button
              type="button"
              className="btn btn-blue"
              style={{ gap: '2rem', minWidth: '0', justifyContent: 'center' }}
              onClick={() => navigate(`/creditapplication/${id}`)}
            >
              <i className="fa-regular fa-arrow-left" />
              {t('flinks.back')}
            </button>
          </div>
        </main>
      )}
    </>
  )
}

export default FlinksIncomeValidationPage
