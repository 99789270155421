import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { useApiClient } from './api-client'

const AMOUNT_FOR_FREQUENCY = 'amountForFrequency'
const AMOUNT_FOR_PROMOTIONAL_CREDIT_APP = 'amountForPromotionalCreditApp'

const keysFactory = {
  amountForFrequency: (computeParams: ComputeAmountForFrequencyDto | null) =>
    [{ scope: AMOUNT_FOR_FREQUENCY }, computeParams] as const,
  amountForPromotionalCreditApp: (id: string) => [{ scope: AMOUNT_FOR_PROMOTIONAL_CREDIT_APP, id }],
}

//-----------------------------------  computeAmountForFrequency  ------------------------
export type ComputeAmountForFrequencyDto = {
  amount: number
  adminFee: number
  rate: number
  paymentFrequency: string
  activationDate: Date
}
export type ComputedAmountForFrequency = {
  termAmountPair: TermAmountPair[]
}
export type TermAmountPair = {
  term: number
  amount: number
}

const computePaymentAmountForFrequency = async ({
  queryKey: [_, computeParams],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['amountForFrequency']>>) => {
  const apiClient = useApiClient()
  const response = await apiClient.post('api/CreditApplication/computeAmountForFrequency', computeParams)

  return response.data as ComputedAmountForFrequency
}

export type PersonalLoanFundingComputed = {
  icebergLenderAdminFee: number
  totalInterestAmount: number
  insuranceFee: number
  insuranceTax: number
  paymentForFrequency: number
  effectiveRate: number
  requestId: string
}

export function useComputePaymentAmountForFrequency(
  computeParams: ComputeAmountForFrequencyDto | null,
): [ComputedAmountForFrequency | null, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: [...keysFactory.amountForFrequency(computeParams)],
    queryFn: computePaymentAmountForFrequency,
    enabled: computeParams !== null,
  })

  return [data ?? null, isFetching]
}

const getPromotionalCreditAppPaymentAmount = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['amountForPromotionalCreditApp']>>) => {
  const apiClient = useApiClient()
  const response = await apiClient.post<PersonalLoanFundingComputed>(
    `api/CreditApplication/${encodeURIComponent(id)}/computePersonalLoanFunding`,
  )
  return response.data as PersonalLoanFundingComputed
}

export function useGetPromotionalCreditAppPaymentAmount(
  creditAppId: string,
  hasPromotionalRate: boolean,
): [PersonalLoanFundingComputed | null, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.amountForPromotionalCreditApp(creditAppId),
    queryFn: getPromotionalCreditAppPaymentAmount,
    enabled: !!creditAppId && hasPromotionalRate,
  })

  return [data ?? null, isFetching]
}
