import { Enum } from '@src/types/constants'

export const MEDICARD_ID = '20878'
export const IFINANCE_ID = '20500'
export function isMedicardMerchant(merchantId: string) {
  const id = extractNumbersFromMerchantId(merchantId)
  return id === MEDICARD_ID
}

export function isIFinanceMerchant(merchantId: string) {
  const id = extractNumbersFromMerchantId(merchantId)
  return id === IFINANCE_ID
}

export function isDefaultMerchant(merchantId: string) {
  const id = extractNumbersFromMerchantId(merchantId)
  return id === IFINANCE_ID || id === MEDICARD_ID
}
export function isMedicardLoanPurpose(loanPurposeId: string | null | undefined) {
  return loanPurposeId == Enum.ELoanPurpose.Medical || loanPurposeId === Enum.ELoanPurpose.Aesthetics
}
export function extractNumbersFromMerchantId(merchantId: string) {
  const parts = merchantId.split('alis*').filter((x) => x.length > 0)
  return parts[0]
}
