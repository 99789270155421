import ReactGA from 'react-ga4'

export function initializeGAnalytics(): void {
  const USED_GOOGLE_MEASURE_ID: string | undefined = window.location.href.includes('ifinancecanada')
    ? process.env.REACT_APP_IFI_GOOGLE_MEASURE_ID
    : process.env.REACT_APP_MED_GOOGLE_MEASURE_ID
  // eslint-disable-next-line no-console
  if (!USED_GOOGLE_MEASURE_ID) console.log('GAnalytics init failed')
  else {
    ReactGA.initialize(USED_GOOGLE_MEASURE_ID)
  }
}
