import { ChangeEventHandler, DetailedHTMLProps, HTMLAttributes, useState } from 'react'
import { Trans } from 'react-i18next'

export interface RadioButtonListOption {
  id: string
  monthsEstimatedAt: string
  loanTermText: string
  loanAmountText: string
}

interface RadioButtonListProps {
  id?: string
  name: string
  options: RadioButtonListOption[]
  defaultValue?: string | undefined | null
  onChange: ChangeEventHandler<HTMLInputElement>
}

export default function RadioButtonList({ id, name, options, defaultValue, onChange }: RadioButtonListProps) {
  const [selectedValue, setSelectedValue] = useState(defaultValue)

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.currentTarget.value
    onChange(e)
    setSelectedValue(newValue)
  }

  return (
    <div className="radiobuttons-stacked-cards">
      {[...options].reverse().map((option) => (
        <div key={option.id} className="radiobuttons-stacked-card-item" id={id}>
          <input
            required
            type="radio"
            id={`${name}_${option.id}`}
            name={name}
            checked={selectedValue === option.id}
            value={option.id}
            onChange={handleChange}
          />
          <label htmlFor={`${name}_${option.id}`}>
            <Trans>
              <span className="info-bold">{option.loanTermText}</span>{' '}
              <span className="info-gray">{option.loanAmountText}</span>
            </Trans>
          </label>
        </div>
      ))}
    </div>
  )
}
