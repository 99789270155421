import React from 'react'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { ManageCoappButton } from '@src/components'
import { useTranslation } from 'react-i18next'
import { ETrackedEvents, useTrackStepReachedEvent } from '@src/services/trackingEvents'

type Props = {
  creditApp: FilteredCreditApplication
}

export default function DeclinedPrequalForm({ creditApp }: Props) {
  const { t } = useTranslation()

  useTrackStepReachedEvent(creditApp, ETrackedEvents.PrequalDeclined)

  return (
    <main className="general-message">
      <h4 className="subtitle">{t('common.financeApplication')}</h4>
      <form>
        <h3>{t('common.refused', { forStep: t('common.prequalified') })}</h3>
        <div className="btn-group" style={{ justifyContent: 'end' }}>
          <ManageCoappButton creditAppId={creditApp.id} useChangeLabel={creditApp.coapplicant !== null} />
        </div>
      </form>
    </main>
  )
}
