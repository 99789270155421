import React, { useCallback, useLayoutEffect, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import LoanAmountForm from './components/LoanAmountForm'
import LoanPurposeForm from './components/LoanPurposeForm'
import ServiceProviderForm from './components/ServiceProviderForm'
import PersonalInformationForm from './components/PersonalInformationForm'
import ContactInformationForm from './components/ContactInformationForm'
import AddressForm from './components/AddressForm'
import EmploymentForm from './components/EmploymentForm'
import ReviewPrequalification from './components/ReviewPrequalification'
import StepperFormFooter from './components/StepperFormFooter'
import { buildDefaultPrequalification, useConvertedLoanPurposeId, useNewApplicationStepList } from './submit-app-hooks'
import { Merchant, useMerchantById } from '@src/api/merchants-api'
import StepProgressionBar from './components/StepProgressionBar'
import { scrollToTop } from '@src/services/utils'
import { Applicant } from './components/PrequalificationSchema'
import { useAppStore } from '@src/data/AppContext'
import { Loader } from '@src/components'
import { useTranslation } from 'react-i18next'
import { isMedicardMerchant } from '@src/data/merchant-selectors'
import { isMedicardWebSite } from '@src/services/query-string'

type SubmitNewAppFormProps = {
  initialMerchant: Merchant
}
function SubmitNewAppForm({ initialMerchant }: SubmitNewAppFormProps) {
  useEffect(() => {
    navigate(`/${urlMerchantId}/new-app/loan-amount`)
  }, [])

  const urlParams = useParams()
  const urlMerchantId = urlParams.merchantId as string
  const currentStep = urlParams.currentStep as string
  const appStore = useAppStore()
  const navigate = useNavigate()

  const [disableNext, setDisableNext] = useState(false)

  // on utilise le merchantId de l'url pour ne pas faire changer la liste de steps quand
  // quand l'utilisateur selectionne un marchand
  const steps = useNewApplicationStepList(urlMerchantId)
  const currentStepId = steps.indexOf(currentStep) + 1
  const isMedicardHost = isMedicardWebSite()
  const isMedicard = isMedicardMerchant(urlMerchantId) || isMedicardHost
  const cssActiveStep = isMedicard && currentStepId > 1 ? currentStepId + 1 : currentStepId
  const cls = `form-section step-${cssActiveStep}-active`

  const [prequalInfo, setPrequalInfo] = useState(buildDefaultPrequalification(initialMerchant))

  const convertedLoanPurposeId = useConvertedLoanPurposeId(prequalInfo.loanPurposeId)

  useLayoutEffect(scrollToTop, [])

  const gotoStep = useCallback((stepPath: string) => {
    scrollToTop()
    navigate(`/${urlMerchantId}/new-app/${stepPath}`)
  }, [])

  const nextStepAfter = useCallback(
    (currentStep: string) => {
      const nextStepIdx = steps.indexOf(currentStep.toLowerCase()) + 1
      if (nextStepIdx < steps.length) {
        gotoStep(steps[nextStepIdx])
      }
    },
    [steps],
  )

  const handlePrequalificationUpdated = (data: any) => {
    const update = { ...prequalInfo, ...data }
    setPrequalInfo(update)

    if (data.merchantId) appStore.setMerchantId(data.merchantId)
    nextStepAfter(currentStep)
  }

  const handleApplicantUpdated = (data: Partial<Applicant>) => {
    const update = { ...prequalInfo, applicant: { ...prequalInfo.applicant, ...data } }
    setPrequalInfo(update)
    nextStepAfter(currentStep)
  }

  return (
    <main className={cls}>
      <StepProgressionBar steps={steps} currentStep={currentStep} />
      {currentStep === 'loan-amount' && (
        <LoanAmountForm prequalificationData={prequalInfo} onPrequalificationUpdated={handlePrequalificationUpdated} />
      )}
      {currentStep === 'loan-purpose' && (
        <LoanPurposeForm prequalificationData={prequalInfo} onPrequalificationUpdated={handlePrequalificationUpdated} />
      )}
      {currentStep === 'service-provider' && (
        <ServiceProviderForm
          prequalificationData={prequalInfo}
          loanPurposeId={convertedLoanPurposeId}
          onPrequalificationUpdated={handlePrequalificationUpdated}
        />
      )}

      {currentStep == 'personal-information' && (
        <PersonalInformationForm
          prequalificationData={prequalInfo}
          onPrequalificationUpdated={handlePrequalificationUpdated}
          loanPurposeId={prequalInfo.loanPurposeId}
        />
      )}

      {currentStep == 'contact' && (
        <ContactInformationForm onApplicantUpdated={handleApplicantUpdated} applicantData={prequalInfo.applicant} />
      )}

      {currentStep == 'address' && (
        <AddressForm
          applicantData={prequalInfo.applicant}
          onApplicantUpdated={handleApplicantUpdated}
          applicantName={prequalInfo.applicant.firstName}
          setDisableNext={setDisableNext}
        />
      )}

      {currentStep == 'employment' && (
        <EmploymentForm applicantData={prequalInfo.applicant} onApplicantUpdated={handleApplicantUpdated} />
      )}

      {currentStep == 'review' && <ReviewPrequalification gotoStep={gotoStep} prequalification={prequalInfo} />}

      {currentStep != 'review' && (
        <StepperFormFooter
          currentStepIndex={steps.indexOf(currentStep) + 1}
          stepLength={steps.length}
          disableBack={steps.indexOf(currentStep) === 0}
          disableNext={disableNext}
        />
      )}
    </main>
  )
}

function SubmitNewAppPage() {
  const urlParams = useParams()
  const urlMerchantId = urlParams.merchantId as string
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [merchant, isFetchingMerchant, error] = useMerchantById(urlMerchantId)

  useEffect(() => {
    if (error) {
      if ((error as any)?.response?.status === 403) {
        navigate('/get-merchant-error/inactive')
      } else if ((error as any)?.response?.status === 404) {
        navigate('/get-merchant-error/not-found')
      } else {
        navigate('/general-error-page/error')
      }
    }
  }, [error])

  if (isFetchingMerchant) return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />
  if (error) return null
  if (merchant) return <SubmitNewAppForm initialMerchant={merchant} />
  return null
}

export default SubmitNewAppPage
