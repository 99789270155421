import React, { useEffect, useState, useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/Loader'

import { useGetCreditApplicationById } from '@src/api/credit-api'
import { canPollCreditApp, computeHighLevelPage, isServerAnalysingCredit } from './credit-hooks'

import ErrorPage from '../ErrorPage'
import ClosedApplicationTile from './components/ClosedApplicationTile'
import DecisionningErrorTile from './components/DecisionningErrorTile'

import PrequalificationResultPage from './PrequalResultPage'
import HardHitUnapprovedResultPage from './HardHitUnapprovedResultPage'
import InitialApprobationForm from './InitialApprobationPage/InitialApprobationForm'
import HardHitApprovedResultPage from './HardHitApprovedResultPage'
import { useGetSkipBankRequestRequirements } from '@src/api/skip-bank-request-api'
import { useRequiredDocumentsWithStatus } from '@src/data/credit-application-hooks'
import CompletedAppPage from './CompletedApp/CompletedAppPage'
import { scrollToTop } from '@src/services/utils'

export default function RouteToStatePage() {
  const { t } = useTranslation()
  const allParams = useParams()
  const id = allParams.id as string
  const [shouldPoll, setShouldPoll] = useState(false)
  const [creditApp, isFetchingCreditApp] = useGetCreditApplicationById(id, shouldPoll) // if true, poll every 5 secs
  const [skipBankRequiredDocuments, isFetchingReqDocsForSkip] = useGetSkipBankRequestRequirements(id)
  const skipBankRequiredDocumentsWithStatus = useRequiredDocumentsWithStatus(
    skipBankRequiredDocuments,
    creditApp?.documents,
  )
  const highLevelPage = computeHighLevelPage(creditApp, isFetchingCreditApp)

  useEffect(() => {
    if (creditApp) {
      const should = isServerAnalysingCredit(creditApp) && canPollCreditApp(creditApp)
      setShouldPoll(should)
    }
  }, [creditApp])

  useLayoutEffect(scrollToTop, [])

  if (isFetchingReqDocsForSkip || isFetchingCreditApp || highLevelPage === 'loading') {
    return (
      <div>
        <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />
      </div>
    )
  }

  return (
    <>
      {highLevelPage === 'notFound' && <ErrorPage title={t('common.notFound')} />}

      {highLevelPage === 'analysis-running' && (
        <div>
          <Loader
            title={t('finalDecision.processing')}
            description={`${t('common.pleaseWait')} ${t('common.withYouShortly')}`}
          />
        </div>
      )}

      {highLevelPage === 'cancelling' && (
        <main className="form-section">
          <div style={{ textAlign: 'center', marginTop: '30%' }}>
            <h3>{t('customerFeedback.customerConfirmation')}</h3>
          </div>
        </main>
      )}

      {highLevelPage === 'prequal-result' && <PrequalificationResultPage creditApp={creditApp!} />}

      {highLevelPage === 'decisionning-error' && <DecisionningErrorTile />}

      {highLevelPage === 'first-approval' && <InitialApprobationForm creditApp={creditApp!} />}

      {highLevelPage === 'hardhit-result' && <HardHitUnapprovedResultPage creditApp={creditApp!} />}

      {highLevelPage === 'approved' && (
        <HardHitApprovedResultPage
          creditApp={creditApp!}
          skipBankAccountRequiredDocuments={skipBankRequiredDocumentsWithStatus}
        />
      )}

      {highLevelPage === 'closed' && <ClosedApplicationTile creditApp={creditApp!} />}

      {highLevelPage === 'funding' && <CompletedAppPage creditApp={creditApp!} />}
    </>
  )
}
