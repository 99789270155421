import React from 'react'
import RequiredDocumentTable from './RequiredDocumentTable'
import { Enum } from '@src/types'
import { FilteredCreditApplication } from '@src/api/credit-api'
import { useRequiredDocumentsWithStatus } from '@src/data/credit-application-hooks'
import { useTranslation } from 'react-i18next'

type Props = {
  creditApp: FilteredCreditApplication
}
function UploadFilesTable({ creditApp }: Props): JSX.Element {
  const reqDocList = useRequiredDocumentsWithStatus(creditApp.requiredDocuments, creditApp.documents)
  const { t } = useTranslation()
  const applicantRequiredDocs = reqDocList.filter(
    (d) =>
      d.applicantType !== 'coApplicant' &&
      d.typeId !== Enum.EDocumentType.SignedCVT &&
      d.status !== Enum.EDocumentStatus.Approved,
  )

  const coApplicantRequiredDocs = reqDocList.filter(
    (d) => d.applicantType === 'coApplicant' && d.status !== Enum.EDocumentStatus.Approved,
  )

  return (
    <div className="paragraph" id="paragraph-document">
      <h4 className="subtitle">{t('common.documents')}</h4>
      {applicantRequiredDocs.length > 0 && (
        <RequiredDocumentTable
          applicant={`${creditApp.applicant.firstName} ${creditApp.applicant.lastName}`}
          requiredDocuments={applicantRequiredDocs}
          creditApplicationId={creditApp.id}
        />
      )}
      {creditApp.coapplicant && coApplicantRequiredDocs.length > 0 && (
        <RequiredDocumentTable
          applicant={`${creditApp.coapplicant.firstName} ${creditApp.coapplicant.lastName}`}
          requiredDocuments={coApplicantRequiredDocs}
          creditApplicationId={creditApp.id}
        />
      )}
    </div>
  )
}

export default UploadFilesTable
