import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ApplicantType } from '@src/types'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AcceptTermsCheckbox } from '@src/components'
import BackButton from '@src/components/BackButton'

function FlinksPage(): JSX.Element {
  const params = useParams()
  const id = params.id as string
  const applicantType = params.applicantType as ApplicantType
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const handleContinueToFlinks = (evt: { preventDefault: () => void }) => {
    evt.preventDefault()
    navigate(`/creditapplication/${id}/flinks/${applicantType}/validate`)
  }

  return (
    <main id="flinks-how-it-works" className="general-message">
      <form onSubmit={handleContinueToFlinks}>
        <h4 className="subtitle">{t('common.incomeVerification')}</h4>
        <h2>{t('flinks.ValidateIncomeTitle')}</h2>

        <p style={{ margin: '1rem 0' }}>
          <h4>{t('flinks.whatIsFlinks')}</h4>
        </p>

        <div id="flinks-steps-group">
          <div id="flinks-step" className="applicationInfo-card">
            <h3>{t('flinks.firstStep')}</h3>
            <p className="paragraph">{t('flinks.firstStepDescription')}</p>
            <i className="fa-regular fa-building-columns" />
          </div>
          <div id="flinks-step" className="applicationInfo-card">
            <h3>{t('flinks.secondStep')}</h3>
            <p className="paragraph">{t('flinks.secondStepDescription')}</p>
            <i className="fa-regular fa-money-check" />
          </div>
          <div id="flinks-step" className="applicationInfo-card">
            <h3>{t('flinks.thirdStep')}</h3>
            <p className="paragraph">{t('flinks.thirdStepDescription')}</p>
            <i className="fa-sharp fa-regular fa-magnifying-glass-chart" />
          </div>
        </div>
        <AcceptTermsCheckbox msgOnInvalid="common.acceptConditions" id="acceptAuthorise" termId="">
          {i18n.language === 'fr' && (
            <p className="blue-strong">
              J'ai lu et j'accepte les{' '}
              <strong>
                <a href={`/creditapplication/${id}/flinks/${applicantType}/terms`}>conditions générales</a>
              </strong>{' '}
              d'iFinance
            </p>
          )}
          {i18n.language === 'en' && (
            <p className="blue-strong">
              I have read and agree to iFinance's{' '}
              <strong>
                <a href={`/creditapplication/${id}/flinks/${applicantType}/terms`}>Terms and Conditions</a>
              </strong>
            </p>
          )}
        </AcceptTermsCheckbox>
        <div className="btn-group mobile-reversed">
          <BackButton />
          <button type="submit" className="btn primary-action-btn btn-blue btn-simple" style={{ flexGrow: '1' }}>
            {t('flinks.continueFlinks')}
            <i className="fa-regular fa-arrow-right" />
          </button>
        </div>
      </form>
    </main>
  )
}

export default FlinksPage
