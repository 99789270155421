import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { Enum, RequiredDocumentWithStatus } from '@src/types'
import UploadFileButton from './UploadFileButton'
import { replaceLastSpaceWithNbsp } from '@src/services/utils'
type Props = {
  creditApplicationId: string
  applicant: string
  requiredDocuments: RequiredDocumentWithStatus[]
}

export type CustomError = Error & { id?: string }

function RequiredDocumentTable({ requiredDocuments, applicant, creditApplicationId }: Props) {
  const { t } = useTranslation()
  const [error, setError] = useState<CustomError>()

  const getStatusClass = (status: string) => {
    const statusClassMap = {
      [Enum.EDocumentStatus.AwaitingDocument]: 'text-statusBlue',
      [Enum.EDocumentStatus.AwaitingApproval]: 'text-statusYellow',
      [Enum.EDocumentStatus.Incomplete]: 'text-statusYellow',
      [Enum.EDocumentStatus.Refused]: 'text-statusRed',
      [Enum.EDocumentStatus.Approved]: 'text-statusGreen',
    }

    return statusClassMap[status] || ''
  }

  const hasRefusedDocument = requiredDocuments.some((doc) => doc.status === Enum.EDocumentStatus.Refused)

  const hasIncompletedDocument = requiredDocuments.some((doc) => doc.status === Enum.EDocumentStatus.Incomplete)

  return (
    <>
      <div className="scroll-container">
        <h3> {applicant && `${applicant.charAt(0).toUpperCase()}${applicant.slice(1)}`}</h3>
        <table className="styled-table">
          <thead>
            <tr>
              <th>{t('uploadRequiredFile.documentName')}</th>
              <th>{t('uploadRequiredFile.status')}</th>
              <th>{t('uploadRequiredFile.upload')}</th>
            </tr>
          </thead>
          <tbody>
            {requiredDocuments.map((doc) => (
              <tr key={doc.id}>
                <td data-label={t('common.document')}>
                  {i18n.language === 'fr' ? replaceLastSpaceWithNbsp(doc.nameFr) : replaceLastSpaceWithNbsp(doc.nameEn)}

                  <br />
                  <span className="table-note">{doc.note ?? ''}</span>
                </td>
                <td className="fixed-width-250" data-label={t('uploadRequiredFile.status')}>
                  {error && error.id === doc.id ? (
                    <span className={getStatusClass(Enum.EDocumentStatus.Refused)}>
                      {t(`enum.eDocumentStatus.${Enum.EDocumentStatus.Refused}`)}: {error.message}
                    </span>
                  ) : (
                    <span className={getStatusClass(doc.status)}>{t(`enum.eDocumentStatus.${doc.status}`)}</span>
                  )}
                  {(hasRefusedDocument || hasIncompletedDocument) &&
                    (window.innerWidth > 600 ? (
                      <>
                        <br />
                        <span
                          className="table-note"
                          style={{ color: '#E97472', textAlign: 'justify', paddingRight: '10px' }}
                        >
                          {(doc.status === Enum.EDocumentStatus.Refused ||
                            doc.status === Enum.EDocumentStatus.Incomplete) &&
                            doc.refusalReason}
                        </span>
                      </>
                    ) : (
                      doc.refusalReason !== null && (
                        <>
                          <br />
                          <span
                            className="table-note"
                            style={{
                              color: '#E97472',
                              textAlign: 'justify',
                            }}
                          >
                            {(doc.status === Enum.EDocumentStatus.Refused ||
                              doc.status === Enum.EDocumentStatus.Incomplete) &&
                              doc.refusalReason}
                          </span>
                        </>
                      )
                    ))}
                </td>
                <td data-label={t('uploadRequiredFile.upload')}>
                  <UploadFileButton
                    id={doc.id}
                    creditApplicationId={creditApplicationId}
                    typeId={doc.typeId}
                    subKey={doc.subKey}
                    applicantType={doc.applicantType}
                    disabled={doc.status === Enum.EDocumentStatus.Approved}
                    setError={setError}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
export default RequiredDocumentTable
